import React from 'react'
import styled from 'styled-components/macro'

// border: 1px solid #9c9c9c;
// 	box-shadow: 0 0 2px #9c9c9c;
// 	box-shadow: 0 0 2px #9370d8;

interface IInput {
	w?: string
	h?: string
	fontSize?: string
	border?: string
	boxShadow?: string
	boxShadowFocus?: string
	boxShadowHover?: string
	borderFocus?: string
}

const Input = styled.input<IInput>`
	border: ${({ border }) => (border ? border : '1px solid #738297')};
	border-radius: 5px;
	box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : '0 0')};
	padding: 6px 10px;
	background-color: white;
	color: #000;
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '17px')};
	line-height: 140%;
	min-width: ${({ w }) => (w ? w : '240px')};
	width: ${({ w }) => (w ? w : '100%')};;
	min-height: ${({ h }) => (h ? h : '36px')};
	height: ${({ h }) => (h ? h : '36px')};
	max-height: ${({ h }) => (h ? h : '36px')};
	transition: all 0.3s ease 0s;
	:focus {
		background-color: #fff;
		color: #1B2341;
		border-color: ${({ boxShadowFocus }) => (boxShadowFocus ? '#7154DC' : '')};
		box-shadow: ${({ boxShadowFocus }) => (boxShadowFocus ? boxShadowFocus : '0 0')};
	}
	::placeholder {
		color: #738297;
	}
	:hover {
		box-shadow: ${({ boxShadowHover }) => (boxShadowHover ? boxShadowHover : '0 0')};
	}

	:hover:focus {
		box-shadow: ${({ boxShadowFocus }) => (boxShadowFocus ? boxShadowFocus : '0 0')};
	}

	@media screen and (max-width: 525px) {
		font-size: 17px;
		min-width: 330px;
		width: 100%;
		height: 36px;
		min-height: 36px;
		max-height: 36px;
	}

	@media screen and (max-width: 460px) {
		font-size: 14px;
		min-width: 240px;
	}

	@media screen and (max-width: 360px) {
		padding: 3px 5px;
		font-size: 12px;
	}

	@media screen and (max-width: 340px) {
		min-width: 200px;
		padding: 3px 5px;
		font-size: 10px;
	}

	@media screen and (max-width: 300px) {
		min-width: 150px;
	}
`

export default React.memo(Input)
