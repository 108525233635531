import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { getCookie } from 'typescript-cookie'

import { apiEndPoints } from '../../libs/consts'
import { BASE_API_URL } from '../../libs/utils/api'

import { secureQueryBuilder } from './_api'


const secureQuery = secureQueryBuilder(BASE_API_URL)

export const downloadApi = createApi({
    baseQuery: secureQuery,
    reducerPath: 'downloadApi',
    endpoints:
        (build:
            EndpointBuilder<BaseQueryFn<any | FetchArgs, {}, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
                '', 'downloadApi'>,
        ) => ({
            downloadCSV: build.mutation<any, any>({
                queryFn: async (
                    body,
                    _queryApi: any,
                    _extraOptions: any,
                    baseQuery: any,
                ) => {
                    const result = await baseQuery({
                        url: apiEndPoints.DOWNLOAD_CSV,
                        responseHandler: ((response: any) => response.blob()),
                        method: 'POST',
                        body,
                        headers: {
                            authorization: `Bearer ${getCookie('access_token')}`,
                        },
                    })
                    const hiddenElement = document.createElement('a')
                    const url = window.URL || window.webkitURL
                    const blobRes = url.createObjectURL(result.data)
                    hiddenElement.href = blobRes
                    hiddenElement.target = '_blank'
                    hiddenElement.download = 'Report.csv'
                    hiddenElement.click()
                    document.body.removeChild(hiddenElement)
                    return { data: null }
                },
            }),

        }),
})

export const {
    useDownloadCSVMutation,
} = downloadApi
