import React from 'react'
import styled from 'styled-components/macro'

import InputWithLabel from '../../components/input-with-label/input-with-label'

const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background-color: #fff;
	/* margin: 0px 0px 36px 0px; */
	border-radius: 25px 25px 0px 0px;
	min-width: 250px;
`

const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	gap: 32px;
	> div {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 32px;
	}
`

const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	> div {
		:first-child {
			:hover > svg path {
				transition: all 0.3s ease 0s;
				stroke: #65B92F
			}
		}
		:last-child {
			:hover > svg path {
				transition: all 0.3s ease 0s;
				stroke: #D65A4A
			}
		}
	}
`

const Action = styled.div`
	cursor: pointer;
`

interface IFilterProps {
	setCard: (value: string | undefined) => void
	setId: (value: string | undefined) => void
	setFiatAmount: (value: string | undefined) => void
	setTransationId: (value: string | undefined) => void
	setMid: (value: string | undefined) => void
	setFingerprint: (value: string | undefined) => void
	setDateFrom: (value: string | undefined) => void
	setDateTo: (value: string | undefined) => void
	id: string | undefined
	card: string | undefined
	fiatAmount: string | undefined
	transationId: string | undefined
	mid: string | undefined
	fingerprint: string | undefined
	dateFrom: string | undefined
	dateTo: string | undefined
}


const Filter: React.FC<IFilterProps> = ({
	setCard,
	setId,
	setFiatAmount,
	setTransationId,
	setMid,
	setFingerprint,
	setDateFrom,
	setDateTo,
	id,
	card,
	fiatAmount,
	transationId,
	mid,
	fingerprint,
	dateFrom,
	dateTo,
}) => {

	return (
		<FilterWrapper>
			<InputsWrapper>
				<div>
					<InputWithLabel
						label="orderId"
						placeholder=""
						value={id || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value)}
					/>
					<InputWithLabel
						label="requisites"
						placeholder=""
						value={card || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setCard(e.target.value)}
					/>
					<InputWithLabel
						label="fiatAmount"
						placeholder=""
						value={fiatAmount || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setFiatAmount(e.target.value)}
					/>
					<InputWithLabel
						label="transationId"
						placeholder=""
						value={transationId || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setTransationId(e.target.value)}
					/>
					<InputWithLabel
						label="clientMerchantId"
						placeholder=""
						value={mid || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setMid(e.target.value)}
					/>
					<InputWithLabel
						label="fingerprint"
						placeholder=""
						value={fingerprint || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setFingerprint(e.target.value)}
					/>
					<InputWithLabel
						label="dateFrom"
						placeholder=""
						value={dateFrom || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value.substring(0, 10))}
						type="date"
					/>
					<InputWithLabel
						label="dateTo"
						placeholder=""
						value={dateTo || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value.substring(0, 10))}
						type="date"
					/>
				</div>
			</InputsWrapper>
			{/* <ActionsWrapper>
				<Action onClick={() => { }}>
					<Check />
				</Action>
				<Action onClick={() => { }}>
					<Cancel />
				</Action>
			</ActionsWrapper> */}
		</FilterWrapper>
	)
}

export default React.memo(Filter)