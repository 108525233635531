import 'swiper/css'
import 'swiper/css/zoom'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Navigation, Pagination, Zoom} from 'swiper'

function isValidURL(url) {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    
    return urlPattern.test(url)
}

export const CustomSwiper = (checkArray: any) => (
    <Swiper
        style={{
            // @ts-ignore
            '--swiper-navigation-color': '#000',
            '--swiper-pagination-color': '#000',
            width: '100%',
            height: 'auto',
            minHeight: '300px',
            alignItems: 'center',
        }}
        zoom={true}
        navigation={true}
        loop={true}
        pagination={{
            clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
    >
        {checkArray.length && checkArray?.map((check: any, index: any) => (
            <SwiperSlide style={{ height: 'auto' }} key={index}>
                <div key={'div' + index} style={{ minHeight: '300px', alignItems: 'center' }} className="swiper-zoom-container">
                    {isValidURL(check) ? (
                        <img 
                            src={check}
                            style={{maxHeight: '74vh', maxWidth: '100%'}}
                            alt={'Check'}
                        />
                    ) : (
                        <img 
                            src={`data:image/png;base64,${check}`}
                            style={{maxHeight: '74vh', maxWidth: '100%'}}
                            alt={'Check'}
                        />
                    )}
                </div>
            </SwiperSlide>
            ))}
    </Swiper>
)
