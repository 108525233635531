const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tabletS: '640px',
	tabletM: '768px',
	laptopS: '1024px',
	laptopM: '1280px',
	laptopL: '1440px',
	desktop: '2560px',
}

export const device = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tabletS: `(min-width: ${size.tabletS})`,
	tabletM: `(min-width: ${size.tabletM})`,
	laptopS: `(min-width: ${size.laptopS})`,
	laptopM: `(min-width: ${size.laptopM})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktop})`,
}

export const toFixed = (_x: number) => {
	let x = _x || 0
	if (Math.abs(+x) < 1.0) {
		const e = parseInt(x.toString().split('e-')[1])
		if (e) {
			x *= Math.pow(10, e - 1)
			x = Number('0.' + new Array(e).join('0') + x.toString().substring(2))
		}
	} else {
		let e = parseInt(x.toString().split('+')[1])
		if (e > 20) {
			e -= 20
			x /= Math.pow(10, e)
			x += Number(new Array(e + 1).join('0'))
		}
	}
	return x
}

export const formatCurrencyAmountV2 = (num: number, count = 11) => {
	return String(num).includes('e')
		? toFixed(num).toString().slice(0, count)
		: Number(num)
			.toLocaleString('ru-RU', { useGrouping: true })
			.replace(',', '.')
}

export const rounding_format = (
	number: number | string,
	decimals = 2,
	dec_point = '.',
	thousands_sep = ',',
	type = 'string',
): string | number => {
	let num = number
	const idx = num?.toString().split('').indexOf(',')

	if (typeof number === 'string') {
		num = number.replace(/\s+/g, '')
	}

	if (idx !== -1) {
		const tmp = num?.toString().split('')
		tmp?.splice(idx, 1, '.')
		num = tmp?.join('')
	}

	if (num < 0 || !num) {
		num = '0'
	}

	if (num.toString().split('').includes('e')) {
		num = formatCurrencyAmountV2(+num)
	}

	if (num >= '1' || num >= 1) {
		const sign = num < 0 ? '-' : ''
		const s_num =
			Math.abs(parseInt((num = (+num || 0).toFixed(decimals)))) + ''
		const len = s_num.length
		const tchunk = len > 3 ? len % 3 : 0
		const ch_first = tchunk ? s_num.substring(0, tchunk) + thousands_sep : ''
		const ch_rest = s_num
			.substring(tchunk)
			.replace(/(\d\d\d)(?=\d)/g, '$1' + thousands_sep)
		let ch_last = decimals
			? dec_point + (Math.abs(+num) - +s_num).toFixed(decimals).slice(2)
			: ''

		if (ch_last === '.00') {
			ch_last = ''
		}

		return String(sign + ch_first + ch_rest + ch_last)
	}

	if (num < '1' || num < 1) {
		const res = [] as string[]
		const splitedNum = num.toString().split('')
		let idx = splitedNum.indexOf('.')
		let counter = 0

		if (idx < 0) {
			return num
		}

		splitedNum.splice(idx, 1)
		res.push(splitedNum[0])
		for (idx + 1; idx < splitedNum.length; idx++) {
			if (counter < decimals) {
				res.push(splitedNum[idx])
				if (!(splitedNum[idx] === '0' && splitedNum[idx - 1] === '0')) {
					counter++
				}
			}
		}
		res.splice(1, 0, '.')

		return type === 'string' ? String(res.join('')) : Number(res.join(''))
	}

	return num
}

const fiatCurrencies = ['QWRUB', 'SBERRUB', 'TCSBRUB', 'RFBRUB', 'CARDRUB']
const cryptoCurrencies = ['USDTTRC']

interface ITransaction {
	currency: string;
	crypto_amount: number;
	fiat_amount: number;
	commission: number;
}

export const chooseDirectionTransaction = (transaction: ITransaction) => {
	const findedFiat = fiatCurrencies.find((cur) => cur === transaction.currency)
	const findedCrypto = cryptoCurrencies.find(
		(cur) => cur === transaction.currency,
	)

	if (findedFiat) {
		return {
			fromAmount: transaction.crypto_amount,
			fromCurrency: cryptoCurrencies[0],
			toAmount: transaction.fiat_amount,
			toCurrency: findedFiat,
			course: transaction.fiat_amount / (transaction.crypto_amount || transaction.fiat_amount),
		}
	}

	if (findedCrypto) {
		return {
			fromAmount: transaction.fiat_amount,
			fromCurrency: 'RUB',
			toAmount: transaction.crypto_amount,
			toCurrency: findedCrypto,
			course: transaction.fiat_amount / (transaction.crypto_amount || transaction.fiat_amount),
		}
	}

	return {
		fromAmount: 0,
		fromCurrency: transaction.currency,
		toAmount: 0,
		toCurrency: 'ERROR',
		course: 0,
	}
}

export const findStatus = (num: string) => {
	switch (num) {
		case 'ACCEPTED':
			return 'accepted'
		case 'SUCCESS':
			return 'successful'
		case 'ERROR':
			return 'errorStatus'
		case 'APPEAL':
			return 'appeal'
		case 'EXPIRED':
			return 'canceled'
		default:
			return 'accepted'
	}
}

// export const getStatusAppeal = (item: {
// 	new: number;
// 	prev: number;
// 	external_order_id: string;
// }) => {
// 	if (item.new === 0) {
// 		return `Аппеляция по транзакции ${item.external_order_id.slice(
// 			0,
// 			10,
// 		)} на рассмотрении`
// 	}

// 	return `Статус транзакции ${item.external_order_id.slice(
// 		0,
// 		10,
// 	)} изменен с "${findStatus(item.prev)}" на "${findStatus(item.new)}"`
// }

export const convertBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.readAsDataURL(file)

		fileReader.onload = () => {
			//data:image/png;base64,
			resolve(
				fileReader.result
					?.toString()
					?.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')!,
			)
			// replace(/^data:image\/(png|jpg|jpeg);base64,/, "")!)
		}

		fileReader.onerror = (error) => {
			reject(error)
		}
	})
}

export const addStatusIcon = (status: string) => {
	switch (status) {
		case 'ERROR':
			return '#D55A4A'
		case 'ACCEPTED':
			return '#000'
		case 'SUCCESS':
			return '#65B92F'
		case 'APPEAL':
			return '#F7CC34'
		case 'EXPIRED':
			return '#D55A4A'
		default:
			return '#000'
	}
}

export const makeRandomString = (length: number) => {
	let result = ''
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length))
	}
	return result
}

export const hasProperty = (arr: any[], prop: string): boolean => {
	return arr.some((item) => {
		return item.hasOwnProperty(prop)
	})
}