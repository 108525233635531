import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query'
import { Mutex } from 'async-mutex'

import { getCookie, removeCookie, setCookie } from 'typescript-cookie'

import { setAuthorizationStatus, setRefreshTokenAuth } from '../slices/base-slice'
import { AuthorizationStatus, apiEndPoints } from '../../libs/consts'

export const secureQueryBuilder = (baseUrl: string) => {
	const mutex = new Mutex()
	const baseQueryWithReAuth: BaseQueryFn<
	FetchArgs,
	unknown,
	FetchBaseQueryError
	> = async (args, api, extraOptions) => {

		await mutex.waitForUnlock()
		const baseQuery = fetchBaseQuery({
			baseUrl,
			prepareHeaders: (headers, api) => {
				const token = (api.getState() as any).base.refreshToken || getCookie('access_token')
				if (token) {
					headers.set('authorization', `Bearer ${token}`)
				}
				return headers
			},
		})
		let result = await baseQuery(args, api, extraOptions)
		//@ts-ignore
		if (result.error && result?.error.originalStatus === 401) {
			(getCookie('refresh_token') && api.dispatch(setRefreshTokenAuth(getCookie('refresh_token')!)))
			if (!mutex.isLocked()) {
				const release = await mutex.acquire()
				try {
					const { data }: any = await baseQuery({
						url: apiEndPoints.REFRESH_TOKEN,
						method: 'POST',
						headers: {
							authorization: `Bearer ${getCookie('refresh_token')}`,
						},
					}, api, extraOptions)

					if (data) {
						api.dispatch(setRefreshTokenAuth(''))
						if (window.location.host === 'localhost:3000') {
							setCookie('access_token', data?.access_token, { expires: 7 })
							setCookie('refresh_token', data?.refresh_token, { expires: 1 })
							} else {
								setCookie('access_token', data?.access_token, { expires: 7, domain: '.expay.cash' })
								setCookie('refresh_token', data?.refresh_token, { expires: 1, domain: '.expay.cash' })
							}
						result = await baseQuery(args, api, extraOptions)
					} else {
						api.dispatch(setAuthorizationStatus(AuthorizationStatus.NO_AUTH))
						if (window.location.host === 'localhost:3000') {
							removeCookie('access_token')
							removeCookie('refresh_token')
						} else {
							removeCookie('access_token', { domain: '.expay.cash' })
							removeCookie('refresh_token', { domain: '.expay.cash' })
						}
					}
				} finally {
					release()
				}
			} else {
				await mutex.waitForUnlock()
				result = await baseQuery(args, api, extraOptions)
			}
		}
		return result
	}
	return baseQueryWithReAuth
}
