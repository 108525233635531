import React from 'react'
import styled from 'styled-components/macro'

const Table = styled.table`
	width: 100%;
	flex: 1 1 auto;
    border-spacing: 0;
	font-weight: 400;
	font-size: 17px;
	line-height: 140%;
	color: #000;
	overflow-x: scroll;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	thead {
		background: #fff;
		> tr {
			> th {
				border-bottom: 1px solid #989A9E;
				padding: 10px 24px;
			}
		}
	}
	tbody {
		background-color: #fff;
		> tr {
			&:nth-child(even) {
                background-color: #f9f9f9;
            }
			> td {
				padding: 10px;
				text-align: center;
				border-bottom: 1px solid #ccc;
				* > div {
					> span {
						font-size: 14px;
						color: #000;
					}
				}
			}
		}
	}
	tfoot {
		background: #fff;
		> tr {
			> td {
				padding: 10px 24px;
			}
		}
	}
`

export default React.memo(Table)
