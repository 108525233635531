import React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import Login from '../pages/auth/auth'
import { PATH } from '../libs/consts'

export default function Redirect(props: { to: string }) {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const notifyError = () => toast.error(t('components.notLoggedIn'))

    React.useEffect(() => {
        navigate(props.to)
        notifyError()
    }, [])

    return (
        props.to === PATH.auth
            ? <Login />
            : null
    )
}
