import React from 'react'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../hooks/redux';

//import { useFetchFileQuery, useFetchUserTransactionsCsvsQuery } from '../../store/services/transactions-service'
import { useFetchFileMutation, useFetchUserTransactionsCsvsQuery } from '../../store/services/transactions-service'

import { BASE_API_URL } from '../../libs/utils/api'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    max-height: 400px;
`

const MainContent = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;    
    font-weight: 800;
    line-height: 140%;
    justify-content: center;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }

    @media screen and (max-width: 420px) {
        width: 100%;
        font-size: 20px;  
    }

`

const StyledSpan = styled.span`
	color: #000;
`

const StyledLink = styled.a`
    color: #7154DC;
`

const StyledList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const StyledListItem = styled.li`
    margin: 20px 0;
`

const Downloads = () => {
    const {t} = useTranslation()

    const { data, error, isLoading, isError, refetch } = useFetchUserTransactionsCsvsQuery(undefined, { pollingInterval: 10000 })
    //const [fetchCsv, { isLoading: isLoadingCsv}] = useFetchCsvMutation()
    const [fetchFile] = useFetchFileMutation()

    const handleClick = (e, id, format) => {
        e.preventDefault()
        fetchFile({id: id, format: format})
    }
    
    return (
            <Wrapper>
                
                    <Title >
                        {t('components.downloadsList')}
                    </Title>
                    <MainContent>
                    <StyledList>
                    {data?.reports?.length && (
                        data.reports.map((item) => {
                            const date = new Date(item.time_from)
                            const dateTo = new Date(item.time_to)
                            return (
                                <StyledListItem key={item.id}>
                                {item.is_ready ? (
                                  <StyledLink href={`${BASE_API_URL}lk/report/download/${item.id}`} onClick={(e) => handleClick(e, item.id, item.format)}>
                                    {`Report_from_${date.getDate()}_${date.getMonth()+1}_${date.getFullYear()}_to_${dateTo.getDate()}_${dateTo.getMonth()+1}_${dateTo.getFullYear()}.${item.format}`}
                                  </StyledLink>
                                ) : (
                                  <StyledSpan>{t('components.downloadPrepares')}: {`Report_Orders_from_${date.getDate()}_${date.getMonth()}_${date.getFullYear()}.${item.format}`}</StyledSpan>
                                )}
                              </StyledListItem>
                            )
                        })
                    )}
                    </StyledList>
                </MainContent>
            </Wrapper>
    )
}

export default React.memo(Downloads)