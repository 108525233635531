import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './i18/i18n'
import { ThemeProvider } from 'styled-components'

import store from './store/store'

import './styles/index.scss'
import App from './App'

import { Theme } from './styles/theme'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
)
