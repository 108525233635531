import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { Download } from '../../assets/images/images'

const DropArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    border: 1.5px dashed #32363D;
    border-radius: 4px;
    padding: 32px;
    user-select: none;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    div:first-child {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #000;
    }
    div:last-child {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #000;
    }
`

const StyledUploadButton = styled.label`
    padding: 12px 24px;
    background-color: #32363D;
    color: #fff;
    border-radius: 4px;
    transition: all 0.3s ease 0s;
    :hover {
        cursor: pointer;
        background-color: #373882;
    }
`

interface DragNDropProps {
    isVisible: boolean
    setFiles64: (value: any) => void
}

const DragNDrop: React.FC<DragNDropProps> = ({ isVisible, setFiles64 }) => {
    const {t} = useTranslation()
    const inputRef = React.useRef<HTMLInputElement>(null)    

    const [drag, setDrag] = React.useState(false)

    const dragStartHandler = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(true)
    }, [])

    const dragLeaveHandler = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(false)
    }, [])

    const setFileHandler = React.useCallback((files: FileList) => {
        const filesArray = [...files]
        setFiles64((prev: any) => {
            const arrayOfNames = prev?.map((file: any) => file.name)
            const unique = filesArray.filter((file: any) => !arrayOfNames.includes(file.name))
            return [...prev, ...unique]
        })
        setDrag(false)
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }, [])

    const onDropHandler = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const files = e.dataTransfer.files
        setFileHandler(files)
    }, [setFileHandler])

    const getFileHandler = React.useCallback((e: any) => {
        e.preventDefault()
        const files = e.target.files!
        setFileHandler(files)
    }, [setFileHandler])

    const pasteFileHandler = React.useCallback((e: ClipboardEvent) => {
        const dt = new DataTransfer()
        const files = e.clipboardData!.items!

        Array.from(files).forEach((_, index: number) => {
            const item = files[index]
            if (item.kind === 'file') {
                return dt.items.add(item.getAsFile() as File)
            }
        })

        if (dt.items.length > 0) {
            const files = dt.files
            setFileHandler(files)
        }
    }, [])

    React.useEffect(() => {
        if (isVisible) {
            document?.body?.addEventListener('paste', pasteFileHandler)
            inputRef.current?.addEventListener('change', getFileHandler)
        }

        return () => {
            if (isVisible) {
                document?.body?.removeEventListener('paste', pasteFileHandler)
                inputRef.current?.removeEventListener('change', getFileHandler)
                setFiles64([])
            }
        }
    }, [isVisible, setFiles64])

    return (
        <DropArea
            onDragStart={e => dragStartHandler(e)}
            onDragLeave={e => dragLeaveHandler(e)}
            onDragOver={e => dragStartHandler(e)}
            onDrop={e => onDropHandler(e)}
        >
            <div>
                <Download />
            </div>
            {drag
                ? <Content>
                    {t('components.dropFile')}
                </Content>
                : <Content>
                    {t('components.dragNDropeFile')}
                </Content>
            }
            <StyledUploadButton className="custom-file-upload">
                <button onClick={() => inputRef.current?.click()}>
                    {t('components.uploadFile')}
                </button>
                <input
                    style={{ display: 'none' }}
                    ref={inputRef}
                    type="file"
                    // onChange={getFileHandler}
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                />
            </StyledUploadButton>
        </DropArea>
    )
}

export default DragNDrop