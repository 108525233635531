// @ts-nocheck
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { getCookie } from 'typescript-cookie'

import BalanceCard from '../../components/balance-card/BalanceCard'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { rounding_format } from '../../libs/utils/utils'

import balanceIcon from '../../assets/images/balance.svg'
import invoiceIcon from '../../assets/images/invoice.svg'
import withdrawalIcon from '../../assets/images/withdrawal.svg'
import courseIcon from '../../assets/images/course.svg'
import { useGetBalanceQuery } from '../../store/services/balance-service'
import { IBalance } from '../../types/balance'
import { useGetCourseMMutation } from '../../store/services/course-service'
import { useGetCashflowQuery } from '../../store/services/cashflow-service'
import { useLazyRefreshQuery } from '../../store/services/base-service'

const Wrapper = styled.div`
	width: 100%;
	height: max-content;
	display: flex;
    justify-content: center;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap;
`

const BalanceCards: React.FC<{}> = () => {
	const location = useLocation()
	const { token } = useAppSelector(state => state.currency)

	const [refresh] = useLazyRefreshQuery()

	const { data: balanceData, isError: isErrorBalanceData } = useGetBalanceQuery(getCookie('is_fiat') === 'true' && location.pathname === '/withdraw' ? 'USDTTRC' : token?.token || 'CARDRUBP2P', { pollingInterval: 15000, refetchOnMountOrArgChange: true })
	const [getCourse, { data: course, isError: isErrorCourse }] = useGetCourseMMutation()
	const defaultBalanceData = [{ amount: 10000, currency: 'USDT' }]

	const {balance_access, statistics_access} = useAppSelector(state => state.base.accesses)
	const isSubUser = useAppSelector(state => state.base.isSubUser)

	React.useEffect(() => {
		if (isErrorBalanceData || isErrorCourse) {
			refresh(null)
		}
	}, [isErrorBalanceData, isErrorCourse])

	React.useEffect(() => {
		if (getCookie('is_fiat') === 'false') {
			setTimeout(() => {
				getCourse(token?.token || 'CARDRUBP2P')
			}, 15000)
		}
	}, [])

	const balanceCard = React.useMemo(() => {
		return (
			<BalanceCard
				values={balanceData?.map(
					(balance: IBalance) => +rounding_format(balance?.amount!, 2, '.', ''),
				)}
				freeze={balanceData?.map(
					(balance: IBalance) => +rounding_format(balance?.freeze_amount!, 2, '.', ''),
				)}

				// profitBalance='24'
				label="balance"
				bg="#DA932C"
				arr={balanceData || defaultBalanceData}
				icon={balanceIcon} 
			/>
		)
	}, [balanceData])

	const courseCard = React.useMemo(() => {
		return (
			<BalanceCard
				values={[course?.buy_rate, course?.sell_rate]?.map((courseItem: any, index: number) => {
					// const percent = index === 0 ? course.price / 100 : course.price / 100
					return +rounding_format(courseItem, 2, '.', '')
					// return rounding_format(course.price, 2, '.', '')
				})}
				// profitBalance='5%'
				label="rate"
				bg="#2198F1"
				arr={[{ currency: 'buy' }, { currency: 'sell' }]}
				icon={courseIcon}
			/>
		)
	}, [course])

	return (
		<Wrapper>
			{isSubUser ? !!balance_access && balanceCard : balanceCard}
			{token?.type === 'P2P' && getCookie('is_fiat') === 'false' ? courseCard
				: null
			}
		</Wrapper>
	)
}

export default React.memo(BalanceCards)
