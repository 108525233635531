import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

import {
  fetchCourseHandler,
} from '../actions/base-actions'


export interface ICourse {
  left: string
  price: number
  right: string
  currency?: string
}

export interface ICourseState {
  course: ICourse[]
}

const initialState: ICourseState = {
  course: [],
}

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {

  },
  extraReducers: (builder: ActionReducerMapBuilder<ICourseState>) => {
    fetchCourseHandler(builder)
  },
})

export const {
} = courseSlice.actions


export default courseSlice.reducer
