import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { getCookie } from 'typescript-cookie'

import Button from '../button/button'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'

import { useCurrencies } from '../../hooks/use-currencies'
import { setCurrencyGlobal } from '../../store/slices/currency-slice'
import { TToken } from '../../types/types'
import { useTokens } from '../../hooks/use-tokens'


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    @media screen and (max-width: 540px) {
        margin-right: auto;
    }
`

const ModalButton = styled.button`
    height: 32px;
    background: #7154DC;
    border-radius: 6px;
    padding: 8px;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #fff;

    @media screen and (max-width: 330px) {
        min-width: 32px;
        min-height: 32px;
        width: auto;
        height: max-content;
        line-height: 10px;
        font-size: 8px;
        word-wrap: break-word;
    }
`

const List = styled.div`
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    border-top: 1px solid #E1E1E1;
    padding-top: 10px;
`

const Currency = styled.div<{ active: boolean }>`
    padding: 8px;
    width: 100%;
    transition: all 0.3s ease 0s;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    ${({ active }) => active
        ? {
            backgroundColor: '#b1a5da',
            borderRadius: '10px',
            color: '#7154DC',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '-0.01em',
        }
        : {}
    }

    img {
        width: 32px;
        height: 32px;
    }
    :hover {
        cursor: pointer;
    }
`

const CurrencySpan = styled.div`
    display: flex;
    flex-direction: column;
    .curency-span_name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }
    .curency-span_code {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #5D6785;
    }
`

const Currencies: React.FC = () => {
    const currencies = useTokens()
    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.currency.token)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const {t} = useTranslation()
    // const [isFiat, setIsFiat] = useState(true)

    // const handleShowFiat = () => setIsFiat(true);
    // const handleShowCrypto = () => setIsFiat(false);

    // const tokenDictionary = ['CARDRUBP2P', 'INRP2P', 'UZSP2P', 'TRYP2P', 'GBPP2P', 'EURP2P', 'AZNP2P', 'KZTP2P', 'CARDRUB']

    const isCryptoTokens = currencies?.some((token: TToken) => token.type === 'CRYPTO')
    const isAcquiringTokens = currencies?.some((token: TToken) => token.type === 'ACQUIRING')

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (!token?.full_name && getCookie('is_fiat') === 'true' && (currencies?.some((item) => item.type === 'P2P' || currencies[0]?.full_name))) {
        dispatch(setCurrencyGlobal(currencies?.filter((item) => item.type === 'P2P')[0] || currencies[0]))
    }
    }, [currencies])

    const handleSelectCurrency = React.useCallback((token: TToken) => {
        dispatch(setCurrencyGlobal(token))
    }, [])

    return (
        <Wrapper>
            {currencies[0].full_name && getCookie('is_fiat') === 'true' && (
                <ModalButton onClick={showModal}>
                    <span>{token?.full_name || currencies.filter((item) => item.type === 'P2P')[0]?.full_name || currencies[0]?.full_name}</span>
                </ModalButton>
            )}
            {getCookie('is_fiat') !== 'true' && (
                <ModalButton onClick={showModal}>
                    <span>{token?.full_name || t('components.all')}</span>
                </ModalButton>
            )}
            <Modal title={t('components.chooseToken')} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                width={750}
                footer={null}
                style={{
                    height: '517px',
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01), 0px 10px 20px rgba(196, 184, 254, 0.1)',
                    borderRadius: '20px',
                }}
                bodyStyle={{ height: '517px', overflowY: 'auto' }}>
                {/* <SearchDiv>Поиск</SearchDiv> */}
                <List>
                    <Wrapper>
                       <div style={{width: !isCryptoTokens || !isAcquiringTokens ? !isCryptoTokens && !isAcquiringTokens ? '100%' : '45%' : '30%'}}>
                            <span style={{fontSize: 20, fontWeight: 'bold', padding: '10px 10px'}}>Fiat</span>
                            <div>
                            {getCookie('is_fiat') === 'false' && (<Currency
                                key="-1"
                                active={token?.token === ''}
                                onClick={() => {
                                    handleSelectCurrency({ full_name: '', token: '', type: '' })
                                    handleCancel()
                                }}
                                    >
                                <CurrencySpan >
                                    <span className="curency-span_name">ALL</span>
                                </CurrencySpan >
                            </Currency >)}
                            {currencies?.map((cur: TToken, index: number) => {
                                if (cur?.type === 'P2P') {
                                    return (
                                        <Currency
                                            key={index}
                                            active={token?.token === cur.token}
                                            onClick={() => {
                                                handleSelectCurrency(cur)
                                                handleCancel()
                                            }}
                                        >
                                            < CurrencySpan >
                                                <span className="curency-span_name">{cur.full_name}</span>
                                                <span className="curency-span_code">{cur.token}</span>
                                            </CurrencySpan >
                                        </Currency >)

                                }})}
                            </div>
                        </div>
                        {isCryptoTokens && <div style={{width: !isCryptoTokens || !isAcquiringTokens ? !isCryptoTokens && !isAcquiringTokens ? '100%' : '45%' : '30%'}}>
                        <span style={{fontSize: 20, fontWeight: 'bold', padding: '10px 10px'}}>Crypto</span>
                        {isCryptoTokens && currencies?.map((cur: TToken, index: number) => {
                                if (cur?.type === 'CRYPTO') {
                                    return (
                                        <Currency
                                            key={index}
                                            active={token?.token === cur.token}
                                            onClick={() => {
                                                handleSelectCurrency(cur)
                                                handleCancel()
                                            }}
                                        >
                                            < CurrencySpan >
                                                <span className="curency-span_name">{cur.full_name}</span>
                                                <span className="curency-span_code">{cur.token}</span>
                                            </CurrencySpan >
                                        </Currency >)

                                }})}
                        </div>}
                        {isAcquiringTokens && <div style={{width: !isCryptoTokens || !isAcquiringTokens ? !isCryptoTokens && !isAcquiringTokens ? '100%' : '45%' : '30%'}}>
                        <span style={{fontSize: 20, fontWeight: 'bold', padding: '10px 10px', whiteSpace: 'nowrap' }}>Acquiring</span>
                        {currencies?.map((cur: TToken, index: number) => {
                                if (cur?.type === 'ACQUIRING') {
                                    return (
                                        <Currency
                                            key={index}
                                            active={token?.token === cur.token}
                                            onClick={() => {
                                                handleSelectCurrency(cur)
                                                handleCancel()
                                            }}
                                        >
                                            < CurrencySpan >
                                                <span className="curency-span_name">{cur.full_name}</span>
                                                <span className="curency-span_code">{cur.token}</span>
                                            </CurrencySpan >
                                        </Currency >)

                                }})}
                        </div>}
                        </Wrapper>
                    {/* {tokenList()} */}
                </List>
            </Modal>
        </Wrapper>
    )
}

export default React.memo(Currencies)
