import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { TReport } from '../../types/reports'

import baseService from '../services/base-service'
import { IReportsState } from '../slices/reports-slice'

export const appeal = createAsyncThunk(
    'appeal',
    async function (data: {
        external_tx_id: string
        message: string
        file: string
    }, { rejectWithValue }) {
        try {
            const res = await baseService.appeal(data)
            return res
        } catch (err) {
            rejectWithValue(err)
        }
    },
)
export const appealHandler = (builder: ActionReducerMapBuilder<IReportsState>) => {
    builder.addCase(appeal.fulfilled, (state, action: PayloadAction<{ success: boolean }>) => {
        state.isSuccessNewAppeal = action.payload.success
    })
}





export const getReports = createAsyncThunk(
    'getReports',
    async function (_, { rejectWithValue }) {
        try {
            const res = await baseService.get_reports()
            return res
        } catch (err) {
            rejectWithValue(err)
        }
    },
)
export const getReportsHandler = (builder: ActionReducerMapBuilder<IReportsState>) => {
    builder.addCase(getReports.fulfilled, (state, action: PayloadAction<{result: TReport[]}>) => {
        state.reports = action.payload.result
    })
}
