import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { addStatusIcon, findStatus, rounding_format } from '../../libs/utils/utils'

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const ConversionTableCard = ({ item }) => {
  const date = new Date(item?.create_time).toLocaleString()
  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', margin: '16px auto', backgroundColor: 'white', minWidth: '250px', maxWidth: '600px'}}>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ backgroundColor: addStatusIcon(item?.status), display: 'flex', padding: '5px', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: '600' }}>
          {t(`components.${findStatus(item?.status)}`)}
        </div>
      </div>
      <div>
        <div>
          <strong>{t('components.operation')}:</strong> {t('components.conversion')}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.date')}:</strong> <span style={{ marginLeft: '4px' }}><DateWrapper>{date}</DateWrapper></span>
        </div>
        <div style={{ display: 'flex', alignItems: 'start'}}>
          <strong>{t('components.send')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', overflow: 'auto', flexWrap: 'wrap' }}>
            <span style={{ color: '#D65A4A', fontWeight: '600', fontSize: '15px' }}>
            	{`- ${rounding_format(item.amount_from, 2, '.', ',')}`}
            </span>
			<span style={{ marginLeft: '4px' }}>{item.currency_from}</span>
		  </div>
        </div>
        <div>
          <strong>{t('components.rate')}:</strong> {item?.rate}
        </div>
        <div style={{ display: 'flex', alignItems: 'start'}}>
          <strong>{t('components.get')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', overflow: 'auto', flexWrap: 'wrap' }}>
            <span style={{ color: '#65B92F', fontWeight: '600', fontSize: '15px' }}>
            	{`+ ${rounding_format(item.amount_to, 2, '.', ',')}`}
            </span>
			<span style={{ marginLeft: '4px' }}>{item.currency_to}</span>
		  </div>
        </div>
      </div>
    </div>
  )
}

export default ConversionTableCard