export const ChevronDown = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const BellImage = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 23.8286H8M8 23.8286H23M8 23.8286V14.5714C8 12.5255 8.79018 10.5633 10.1967 9.1166C11.6032 7.6699 13.5109 6.85714 15.5 6.85714C17.4891 6.85714 19.3968 7.6699 20.8033 9.1166C22.2098 10.5633 23 12.5255 23 14.5714V23.8286M23 23.8286H26M14.75 6.08571V3.77143C14.75 3.56683 14.829 3.37062 14.9697 3.22595C15.1103 3.08128 15.3011 3 15.5 3C15.6989 3 15.8897 3.08128 16.0303 3.22595C16.171 3.37062 16.25 3.56683 16.25 3.77143V6.08571M17 28.4571C17 28.8663 16.842 29.2588 16.5607 29.5481C16.2794 29.8375 15.8978 30 15.5 30C15.1022 30 14.7206 29.8375 14.4393 29.5481C14.158 29.2588 14 28.8663 14 28.4571H17Z" stroke="#989A9E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="22" cy="9" r="4.75" fill="#79FB4D" stroke="#32363D" strokeWidth="1.5" />
        </svg>
    )
}

export const Download = () => {
    return (
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.125 29.375V31.725C41.125 35.0153 41.125 36.6605 40.4847 37.9172C39.9214 39.0226 39.0226 39.9214 37.9172 40.4847C36.6605 41.125 35.0153 41.125 31.725 41.125H15.275C11.9847 41.125 10.3395 41.125 9.08281 40.4847C7.97735 39.9214 7.07859 39.0226 6.51534 37.9172C5.875 36.6605 5.875 35.0153 5.875 31.725V29.375M33.2917 19.5833L23.5 29.375M23.5 29.375L13.7083 19.5833M23.5 29.375V5.875" stroke="#000" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const Check = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6663 8L11.9997 22.6667L5.33301 16" stroke="#738297" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const Cancel = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 7L25 25M7 25L25 7" stroke="#738297" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const FilterIcon = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.8337 4H3.16699L13.8337 16.6133V25.3333L19.167 28V16.6133L29.8337 4Z" stroke="#738297" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

// test commit

export const SupportIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#E3EDFF" />
            <path d="M16 21.5H14.5C13.1044 21.5 12.4067 21.5 11.8389 21.6722C10.5605 22.06 9.56004 23.0605 9.17224 24.3389C9 24.9067 9 25.6044 9 27M21.5 13.5C21.5 15.9853 19.4853 18 17 18C14.5147 18 12.5 15.9853 12.5 13.5C12.5 11.0147 14.5147 9 17 9C19.4853 9 21.5 11.0147 21.5 13.5ZM18 27L21.1014 26.1139C21.2499 26.0715 21.3241 26.0502 21.3934 26.0184C21.4549 25.9902 21.5134 25.9558 21.5679 25.9158C21.6293 25.8707 21.6839 25.8161 21.7932 25.7068L28.25 19.25C28.9404 18.5597 28.9404 17.4403 28.25 16.75C27.5597 16.0596 26.4404 16.0596 25.75 16.75L19.2932 23.2068C19.1839 23.3161 19.1293 23.3707 19.0842 23.4321C19.0442 23.4866 19.0098 23.5451 18.9816 23.6066C18.9497 23.6759 18.9285 23.7501 18.8861 23.8987L18 27Z" stroke="#547FE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
