import React from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'


const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    cursor: copy;
`

interface ICopy {
    children: React.ReactNode
}

const Copy: React.FC<ICopy> = ({ children }) => {
    const copyRef = React.useRef<HTMLDivElement>(null)
    const {t} = useTranslation()
    const notifyError = () => toast.error(t('components.errorCopy'))
    const notifySuccess = () => toast.success(t('components.successCopy'))

    const copyToClipboard = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()

        if (copyRef && copyRef.current) {
            const arr = (copyRef.current.firstChild?.textContent || copyRef.current.innerText).split('')

            const idx = arr.indexOf('\n')
            idx !== -1 && arr.splice(idx)

            const index = arr.indexOf(' ')
            index !== -1 && arr.splice(index)

            navigator.clipboard
                .writeText(arr.join(''))
                .then(() => {
                    notifySuccess()
                })
                .catch(() => {
                    notifyError()
                })
        }
    }, [copyRef])

    return (
        <Wrapper onClick={copyToClipboard}>
            <div
                style={{ wordBreak: 'break-all', width: 'fit-content', textAlign: 'start' }}
                ref={copyRef}
            >
                {children}
            </div>
        </Wrapper>
    )
}

export default React.memo(Copy)
