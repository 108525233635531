import styled from 'styled-components/macro'
import React from 'react'
import { Link } from 'react-router-dom'

import Button from '../button/button'

const StyledPaginationButton = styled(Button).attrs({
    variant: 'primary',
})`
min-width: 30px;    
width: auto;
    height: 30px;
    padding: 4px;
    ${(props) =>
        !props.active
            ? {
                background: 'transparent',
                border: '2px solid transparent',
                color: '#827e8c',
            }
            : {}}
`

interface IPaginationButtonProps {
    children: React.ReactNode
    url?: string
    onClick: () => void
    active: boolean
    disabled?: boolean
}

const PaginationButton: React.FC<IPaginationButtonProps> = (props) => {
    const { children, url = '' } = props
    const button = <StyledPaginationButton {...props} />
    return (
        <Link to={`${url}?page=${children}`}>
            {button}
        </Link>
    )
}

export default React.memo(PaginationButton)
