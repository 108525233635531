import React from 'react'
import styled from 'styled-components/macro'


interface IButtonProps {
    isSending: boolean
    variant: string
    w: string
    bg: string
    small?: boolean
    disabled?: boolean
}

const Button = styled.button<IButtonProps>`
    padding: ${({ small }) => small ? '6px 16px' : '6px 32px'};
    background: #7154DC;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: ${({ small }) => small ? '14px' : '16px'};
    line-height: 1.2;
    transition: background 0.3s ease, filter 0.3s ease;
    cursor: ${({ isSending }) => isSending ? 'progress' : 'pointer'};
    ${({ variant }) => {
        if (variant === 'secondary') {
            return `
                background: #141625;
            `
        }
        // Default to primary variant
        return `
            background: #7154DC;
        `
    }};
    &:not(:disabled):hover {
        background: ${({ variant }) => (variant === 'secondary' ? '#120f1d' : '#634acf')};
        filter: brightness(85%);
    }

    &:not(:disabled):active {
        transform: translateY(1px);
    }

    &:disabled {
        background: #575170;
        cursor: not-allowed;
    }

    width: ${({ w }) => w ? w : 'auto'};
    background: ${({ bg }) => bg && bg};
`

export default React.memo(Button)
