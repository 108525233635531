import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import Input from '../input/Input'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 4px;
`

const Label = styled.label`

`

interface IInputWithLabelProps {
    label: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    value: string
    placeholder: string
    name?: string
    required?: boolean
    type?: string
    width?: string
    height?: string
    fontSize?: string
    border?: string
    boxShadow?: string
    boxShadowFocus?: string
    boxShadowHover?: string
    borderFocus?: string
    error?: string
    isP2P?: string
    autocomplete?: string
}

const InputWithLabel: React.FC<IInputWithLabelProps> = ({
    label,
    onChangeHandler,
    value,
    placeholder,
    name,
    required,
    type,
    width,
    height,
    fontSize,
    border,
    boxShadow,
    boxShadowFocus,
    boxShadowHover,
    borderFocus,
    error,
    isP2P,
    autocomplete = 'on',
}) => {
    const {t} = useTranslation()
    return (
        <Wrapper>
            {label && <strong style={{ alignSelf: 'start'}}><Label>{t(`components.${label}`)}</Label></strong>}
            <Input
                onChange={onChangeHandler}
                value={value}
                placeholder={placeholder}
                name={name}
                required={required}
                type={type}
                w={width}
                h={height}
                fontSize={fontSize}
                border={border}
                boxShadow={boxShadow}
                boxShadowFocus={boxShadowFocus}
                boxShadowHover={boxShadowHover}
                borderFocus={borderFocus}
                autoComplete={autocomplete}
            />
            {error && <div style={{ color: 'red', fontSize: '12px', alignSelf: 'start' }}>{t(`components.${error}`)}</div>}
            {isP2P && <div style={{ color: 'grey', fontSize: '16px', alignSelf: 'start' }}>≈ {isP2P} USDT</div>}
        </Wrapper>
    )
}

export default React.memo(InputWithLabel)
