import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { IListRequestData, ITransactionsResponseData } from '../../types/types'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(BASE_API_URL)

export interface IResponseTransactions {
	count: number
	transactions: ITransactionsResponseData[]
}

export interface IRequestWithdraw {
	amount: number
	call_back_url: string // кинуть пустую строку
	client_transaction_id: string // рандомная строка
	otp_code: string
	receiver: string // карта для фиата, кошель для тезера
	token: string  // тезер хардкодом
}
export interface IResponseWithdraw {
	status: string
	description: string
	tracker_id: string
}

export interface IRequestInvoice {
	amount: number
	call_back_url: string
	client_transaction_id: string
	card_number: string
	otp_code: string
	token: string
}
export interface IResponseInvoice {
	description: string
	refer: string
	status: string
	token_name: string
	tracker_id: string
}

export const transactionsApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'transactionsApi',
	tagTypes: ['Transactions'],
	endpoints:
		(build:
			EndpointBuilder<BaseQueryFn<string | FetchArgs, IResponseTransactions, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
				'Transactions', 'transactionsApi'>,
		) => ({
			fetchTransactions: build.query<IResponseTransactions, IListRequestData>({
				query(body) {
					return {
						url: apiEndPoints.GET_TRANSACTIONS,
						method: 'POST',
						body,
					}
				},
				transformResponse: (response: { count: number, transactions: ITransactionsResponseData[] }) => response,
				providesTags: () => [{ type: 'Transactions', id: 'LIST' }],
			}),
			createWithdraw: build.mutation<any, any>({
				query(body) {
					return {
						url: apiEndPoints.WITHDRAW,
						method: 'POST',
						body,
					}
				},
				transformResponse: (response) => response,
				invalidatesTags: () => [{ type: 'Transactions', id: 'LIST' }],
			}),
			createInvoice: build.mutation<any, any>({
				query(body) {
					return {
						url: apiEndPoints.CREATE_INVOICE,
						method: 'POST',
						body,
					}
				},
				transformResponse: (response) => response,
				invalidatesTags: () => [{ type: 'Transactions', id: 'LIST' }],
			}),
			fetchTransactionsCsv: build.query<any, any>({
				query(body) {
					return {
						url: apiEndPoints.FETCH_CSV_TRANSACTIONS,
						method: 'POST',
						body,
					};
				},
			}),
			fetchUserTransactionsCsvs: build.query<any, void>({
				query() {
					return {
						url: apiEndPoints.FETCH_USER_CSV_TRANSACTIONS,
						method: 'GET',
					};
				},
			}),
			fetchFile: build.mutation<any, any>({
				queryFn: async (
					{id, format, body},
					_queryApi: any,
					_extraOptions: any,
					baseQuery: any,
				) => {
					const result = await baseQuery({
						url: `${apiEndPoints.FETCH_CSV_FILE}/${id}`,
						responseHandler: ((response: any) => response.blob()),
						method: 'GET',
					})
					const fileFormat = format
					const hiddenElement = document.createElement('a')
					hiddenElement.style.display = 'none'
					const url = window.URL || window.webkitURL
					const blobRes = url.createObjectURL(result.data)
					hiddenElement.href = blobRes
					hiddenElement.target = '_blank'
					hiddenElement.download = `Report_Orders.${fileFormat}`
					hiddenElement.click()
					hiddenElement.remove()
					return { data: null }
				},
			}),
			fetchCsv: build.mutation<any, any>({
				queryFn: async (
					body: any,
					_queryApi: any,
					_extraOptions: any,
					baseQuery: any,
				) => {
					const result = await baseQuery({
						url: apiEndPoints.FETCH_CSV,
						responseHandler: ((response: any) => response.blob()),
						method: 'POST',
						body,
					})
					const format = body?.format ? body.format == 'xls' ? 'xlsx' : body.format : 'csv'
					const hiddenElement = document.createElement('a')
					const url = window.URL || window.webkitURL
					const blobRes = url.createObjectURL(result.data)
					hiddenElement.href = blobRes
					hiddenElement.target = '_blank'
					hiddenElement.download = `Report_Orders_${new Date().toString()}.${format}`
					hiddenElement.click()
					document.body.removeChild(hiddenElement)
					return { data: null }
				},
			}),
			fetchBalanceCsv: build.mutation<any, any>({
				queryFn: async (
					body: any,
					_queryApi: any,
					_extraOptions: any,
					baseQuery: any,
				) => {
					const result = await baseQuery({
						url: apiEndPoints.FETCH_BALANCE_CSV,
						responseHandler: ((response: any) => response.blob()),
						method: 'POST',
						body,
					})
					const format = body?.format ? body.format == 'xls' ? 'xlsx' : body.format : 'csv'
					const hiddenElement = document.createElement('a')
					const url = window.URL || window.webkitURL
					const blobRes = url.createObjectURL(result.data)
					hiddenElement.href = blobRes
					hiddenElement.target = '_blank'
					hiddenElement.download = `Report_Settlement_${new Date().toString()}.${format}`
					hiddenElement.click()
					document.body.removeChild(hiddenElement)
					return { data: null }
				},
			}),
		}),
})

export const {
	useFetchTransactionsQuery,
	useCreateWithdrawMutation,
	useCreateInvoiceMutation,
	useFetchTransactionsCsvQuery,
	useFetchUserTransactionsCsvsQuery,
	useFetchFileMutation,
	useFetchCsvMutation,
	useFetchBalanceCsvMutation,
} = transactionsApi