
import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TToken } from '../../types/types'


export interface IBaseInitialState {
    token: TToken
}

const initialState: IBaseInitialState = {
    token: { full_name: '', token: '', type: '' } as TToken,
}

export const currency = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrencyGlobal: (state, action: PayloadAction<TToken>) => {
            state.token = action.payload
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<IBaseInitialState>) => {
    },
})

export const {
    setCurrencyGlobal,
} = currency.actions

export default currency.reducer
