import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

interface UIInputProps {
	id?: string
	label?: string
	type?: string
	value: string | number
	onChangeHandler: (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void
	required?: boolean
	placeholder?: string
	error?: string
	symbol?: string
	noInput?: boolean
}

const UIInput: FC<UIInputProps> = ({
	id,
	label,
	type, value,
	onChangeHandler,
	required,
	placeholder,
	error,
	symbol,
	noInput = false,
}) => {
	const {t} = useTranslation()
	return (
		<div className="UIInput d-flex flex-column">
			{label && <label className="UIInput-label mb-1">{t(`components.${label}`)}</label>}
			{!noInput &&
				<div className="UIInput-wraper">
					<input
						data-test-id={`test-input-${id}`}
						className={error ? 'UIInput-input UIInput-error-border' : 'UIInput-input' }
						type={type}
						value={value}
						onChange={onChangeHandler}
						onWheelCapture={(e) => {
							(e.target as HTMLInputElement).blur()
						}}
						required={required}
						placeholder={placeholder}
					/>
					{symbol && <div className="UIInput-symbol">{symbol}</div>}
					{error && <div className="UIInput-error">{t(`components.${error}`)}</div>}
				</div>
			}
			{/* {error && <p>{error}</p>} */}
		</div>
	)
}

export default React.memo(UIInput)
