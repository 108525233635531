import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../hooks/redux'
import { addStatusIcon, findStatus } from '../../libs/utils/utils'
import CopyableText from '../copyAbleText/copyAbleText'

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const CryptoCard = ({ item }) => {
  const dateCreate = new Date(item?.date_create).toLocaleString()
  const dateComplete = item?.date_update ? new Date(item?.date_update).toLocaleString() : null
  const token = useAppSelector(state => state.currency.token)
  const {t} = useTranslation()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', margin: '16px auto', backgroundColor: 'white', minWidth: '250px', maxWidth: '600px'}}>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ backgroundColor: addStatusIcon(item?.status), display: 'flex', padding: '5px', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: '600' }}>
          {t(`components.${findStatus(item?.status)}`)}
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>ID:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}> 
            {item.tracker_id ?
                <CopyableText text={item?.tracker_id} />
            : '-'}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            {item.client_transaction_id ?
                <CopyableText text={item?.client_transaction_id} maxLength={30} />
            : '-'}
          </div>
        </div>
        {token.type === 'CRYPTO' && (<div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Hash:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            {item.hash ?
                <CopyableText text={item?.hash} />
            : '-'}
          </div>
        </div>)}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.requisites')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            {item.receiver ?
                <CopyableText text={item?.receiver} maxLength={30} />
            : '-'}
          </div>
        </div>
        <div>
          <strong>{t('components.amount')}:</strong> {item.amount || '-'}
        </div>
        <div>
          <strong>{t('components.commission')}:</strong> {item?.transaction_commission === null ? '-' : String(item?.transaction_commission).slice(0, 10) || '-'}
        </div>
        <div>
          <strong>{t('components.credited')}:</strong> {item?.type === 'IN' ? <span style={{color: '#008000'}}>{`+${item.total}`}</span> : <span style={{color: '#ff0000'}}>{`-${item.total}`}</span>}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.dateCreate')}:</strong> <span style={{ marginLeft: '4px' }}><DateWrapper>{dateCreate}</DateWrapper></span>
        </div>
        {(item?.status === 'ERROR' || item?.status === 'SUCCESS') && dateComplete && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>{t('components.dateComplete')}:</strong> 
            <span style={{ marginLeft: '4px' }}>
              <DateWrapper color={addStatusIcon(item?.status)}>
                {dateComplete}
              </DateWrapper>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CryptoCard
