import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { IListRequestData, IOrdersResponseData } from '../../types/types'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(BASE_API_URL)

export interface IResponseOrders {
	count: number
	orders: IOrdersResponseData[]
}

export const ordersApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'ordersApi',
	tagTypes: ['Orders'],
	endpoints:
		(build:
			EndpointBuilder<BaseQueryFn<string | FetchArgs, IResponseOrders, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
				'Orders', 'ordersApi'>,
		) => ({
			fetchOrders: build.query<IResponseOrders, IListRequestData>({
				query(body) {
					return {
						url: getCookie('is_fiat') === 'false' ? apiEndPoints.GET_HISTORY : apiEndPoints.GET_TRANSACTIONS,
						method: 'POST',
						body,
					}
				},
				transformResponse: (response: { count: number, orders: IOrdersResponseData[] }) => response,
				providesTags: () => [{ type: 'Orders', id: 'LIST' }],
			}),
		}),
})

export const {
	useFetchOrdersQuery,
} = ordersApi
