import {
	createApi,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder('https://cdnv2.desend.io')

interface IResponse {
    file: string
    status: string
}

export const imageApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'imageApi',
	tagTypes: ['Image'],
	endpoints: (
		build: EndpointBuilder<
			BaseQueryFn<
				string | FetchArgs,
				any,
				FetchBaseQueryError,
				{},
				FetchBaseQueryMeta
			>,
			'Image',
			'imageApi'
		>,
	) => ({
		createLink: build.mutation<IResponse, any>({
			query(body) {
				return {
					url: '/save',
					method: 'POST',
                    body,
				}
			},
			transformResponse:  (response: IResponse) => response,
			invalidatesTags: () => [{ type: 'Image', id: 'LIST' }],
		}),
	}),
})

export const {
    useCreateLinkMutation,
} = imageApi
