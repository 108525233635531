import React from 'react'
import styled from 'styled-components/macro'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

import Pagination from '../../components/pagination/pagination'
import Table from '../../components/table/Table'
import { addStatusIcon, findStatus, rounding_format } from '../../libs/utils/utils'
import { ConversionListRequestData, ConversionsResponseData } from '../../types/types'
import { useLazyRefreshQuery } from '../../store/services/base-service'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import useDebounce from '../../hooks/use-debounce'
import { useFetchConversionsQuery } from '../../store/services/conversions-service'
import ConversionTableCard from '../../components/conversion-table-card/ConversionTableCard'
import CSVConversionButton from '../../features/csv-conversion-button.tsx/CsvConversionButton'

const StyledApplicationHead = styled.div`
	display: flex;
	background: transperent;
	padding: 20px;
	justify-content: space-between;
  align-items: end;

  @media screen and (max-width: 750px) {
    flex-direction: column;
		gap: 10px;
    align-items: start;
	}
`

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  z-index: -1;
`

const InputsWrapper = styled.div`
	font-size: 17px;
	line-height: 140%;
	display: flex;
	align-items: center;
	gap: 24px;

	@media screen and (max-width: 1040px) {
		gap: 10px;
	}

	@media screen and (max-width: 980px) {
		gap: 5px;
	}
`

const StatusWrapper = styled.div<{ color: string }>`
	font-size: 14px;
	color: ${({ color }) => color};
`

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const PaginationWrapper = styled.div`
  background: white;
  padding: 0 20px;
  margin-bottom: 160px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);

  @media screen and (max-width: 768px) {
		background: transparent;
    box-shadow: none;
	}
`

const Conversions: React.FC<{}> = () => {
	const windowWidth = window.innerWidth
	const {t} = useTranslation()

	const [offset, setOffset] = React.useState<number>(0)
	const [page, setPage] = React.useState<number>(1)
	const [isFilterClicked, setIsFilterClicked] = React.useState(false)
	const [dateFrom, setDateFrom] = React.useState<string | undefined>(undefined)
  const [dateTo, setDateTo] = React.useState<string | undefined>(undefined)
	const debouncedDateFrom = useDebounce(dateFrom, 500)
	const debouncedDateTo = useDebounce(dateTo, 500)
  const limit = 10

	const [refresh] = useLazyRefreshQuery()

	const data = React.useMemo<ConversionListRequestData>(() => {
		return (
			{
				limit: limit,
				offset: offset,
				date_from: debouncedDateFrom ? new Date(debouncedDateFrom)?.toISOString() : undefined,
      	date_to: debouncedDateTo ? new Date(debouncedDateTo)?.toISOString() : undefined,
			}
		)
	}, [limit, offset, debouncedDateFrom, debouncedDateTo])

	const { data: conversions, isFetching: isFetchingHistory, isLoading: isLoadingHistory, isError: isErrorHistory } = useFetchConversionsQuery(data, { pollingInterval: 15000 })

	const handleOnChangePage = React.useCallback(
		(newPage: number) => {
			const newOffset = (newPage - 1) * limit
			setOffset(newOffset)
			setPage(newPage)
			setIsFilterClicked(true)
		}, [limit])

	React.useEffect(() => {
		if (!isFetchingHistory) {
		  setIsFilterClicked(false)
		}
	  }, [isFetchingHistory, isFilterClicked])

	React.useEffect(() => {
		if (isErrorHistory) {
			refresh(null)
		}
	}, [isErrorHistory])

	return (
		<div style={{ padding: '0 30px 120px' }}>
			{windowWidth > 750 && (
				<StyledApplicationHead>
					<InputsWrapper>
						{isLoadingHistory ? (
					    <div style={{ display: 'flex', marginTop: '40px', gap: window.innerWidth > 980 ? '15px' : '5px'}}>		
						    <div><Skeleton variant="rounded" width={220} height={35} /></div>
						    <div><Skeleton variant="rounded" width={220} height={35} /></div>
					    </div>
				    ) :  (
					    <>
						    <InputWithLabel
						    	label="dateFrom"
						    	placeholder=""
						    	value={dateFrom || ''}
						    	onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value.substring(0, 10))}
						    	type="date"
						    	height="35px"
						    	width="220px"
						    	border="1px solid #e0e0e0"
						    	boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
						    	boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
						    />
						    <InputWithLabel
						    	label="dateTo"
						    	placeholder=""
						    	value={dateTo || ''}
						    	onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value.substring(0, 10))}
						    	type="date"
						    	height="35px"
						    	width="220px"
						    	border="1px solid #e0e0e0"
						    	boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
						    	boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
						    />		
					    </>
				    )}
				  </InputsWrapper>
          {isLoadingHistory ? <Skeleton variant="rounded" width={172} height={32} /> : <CSVConversionButton />}
				</StyledApplicationHead>
			)}
			{windowWidth > 530 && windowWidth <= 750 && (
					<StyledApplicationHead>
						{isLoadingHistory ? (
              <>
              <Skeleton variant="rounded" width={172} height={32} />
						<div style={{ display: 'flex', gap: '20px'}}>
							<div style={{ marginTop: '40px'}}><Skeleton variant="rounded" width={220} height={35} /></div>
							<div style={{ marginTop: '40px'}}><Skeleton variant="rounded" width={220} height={35} /></div>
						</div>
            </>
				) :  (
          <>
            <CSVConversionButton />
							<div style={{ display: 'flex', gap: '20px'}}>
								<InputWithLabel
									label="dateFrom"
									placeholder=""
									value={dateFrom || ''}
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value.substring(0, 10))}
									type="date"
									height="34px"
									width="220px"
									border="1px solid #e0e0e0"
									boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
									boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
								/>
								<InputWithLabel
									label="dateTo"
									placeholder=""
									value={dateTo || ''}
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value.substring(0, 10))}
									type="date"
									height="34px"
									width="220px"
									border="1px solid #e0e0e0"
									boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
									boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
								/>
								</div>
              </>
            )}
					</StyledApplicationHead>
			)}
			{windowWidth <= 530 && (
				<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px', flexDirection: 'column'}}>
					{isLoadingHistory ?	(
						<>
            <Skeleton variant="rounded" width={172} height={32} />
							{[...Array(2)].map((_, index) => (
            		<div key={index} style={{ marginTop: '16px' }}>
              		<Skeleton variant="rounded" width={340} height={35} />
	            	</div>
    	      	))}
        		</>
				) : ( 
			    	<>
            <CSVConversionButton />
						<InputWithLabel
							label="dateFrom"
							placeholder=""
							value={dateFrom || ''}
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value)}
							type="date"
							height="34px"
							width="220px"
							border="1px solid #e0e0e0"
							boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
						/>
						<InputWithLabel
							label="dateTo"
							placeholder=""
							value={dateTo || ''}
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value)}
							type="date"
							height="34px"
							width="220px"
							border="1px solid #e0e0e0"
							boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
						/>
				    </>
				 	)}
				</div>
			)}
			{windowWidth <= 768 && (
				isLoadingHistory || (isFetchingHistory && isFilterClicked) ? (
					<>
          	{[...Array(10)].map((_, index) => (
            	<div key={index} style={{ marginTop: '16px' }}>
              		<Skeleton variant="rounded" height={140} />
            	</div>
          	))}
        	</>
				) : !conversions?.length ? (
					<div style={{ paddingBottom: '200px' }}>
						{t('components.noData')}
					</div>
			  ) : (
					<>
		    			{conversions?.map((item, index) => (
							<ConversionTableCard 
								key={index} 
								item={item} 
						    />
						))}
					</>
				)
			)}
			{windowWidth > 768 && (
				isLoadingHistory || (isFetchingHistory && isFilterClicked) ? (
					<Skeleton variant="rounded" height={550} />
				) : (
				<TableWrapper>
					<Table>
						<thead>
							<tr>
								<th>{t('components.operation')}</th>
                <th>{t('components.date')}</th>
                <th>{t('components.status')}</th>
								<th>{t('components.send')}</th>
								<th>{t('components.rate')}</th>
								<th>{t('components.get')}</th>
							</tr>
						</thead>
						<tbody>
						{!conversions?.length ? (
							<tr>
						  		<td colSpan={6}>
									{t('components.noData')}
								</td>
							</tr>
				) : (
						conversions?.map((item: ConversionsResponseData, index: number) => {
							const date = new Date(item?.create_time).toLocaleString()
							return (
								<tr key={index}>
									<td>
										<div>{t('components.conversion')}</div>
									</td>
									<td>
										<div>
                      <DateWrapper>{date}</DateWrapper>
                    </div>
									</td>
                  <td>
										<StatusWrapper color={addStatusIcon(item.status)}>
											{t(`components.${findStatus(item.status)}`)}
										</StatusWrapper>
									</td>
									<td>
                    <div>
                      <div>
                        <span
                          style={{
                            color: '#D65A4A',
                            fontWeight: '600',
                            fontSize: '17px',
                          }}
                        >
                          -{' '}
                          {rounding_format(item?.amount_from, 2, '.', ',')}
                        </span>
                      </div>
                      <div>
                        <span>{item?.currency_from}</span>
                      </div>
                    </div>
									</td>
									<td>
                    <div>
                      {rounding_format(item?.rate, 2)}
                    </div>
									</td>
									<td>
                    <div>
                      <div>
                        <span
                          style={{
                            color: '#65B92F',
                            fontWeight: '600',
                            fontSize: '17px',
                          }}
                        >
                          + {rounding_format(item?.amount_to, 2, '.', ',')}
                        </span>
                      </div>
                      <div>
                        <span>{item?.currency_to}</span>
                      </div>
                    </div>
									</td>
								</tr>
							)})
						)}
						</tbody>
					</Table>
				</TableWrapper>)
			)}
			<PaginationWrapper>
				<div style={{height: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
					<div style={{marginRight: '50px'}}>
						<Pagination
							page={page}
							offset={offset}
							limit={limit}
							onPageChange={handleOnChangePage}
							total={conversions?.length}
							fetching={isFetchingHistory}
							loading={isLoadingHistory}
						/>
					</div>
				</div>
			</PaginationWrapper>
		</div>
	)
}

export default React.memo(Conversions)