import React from 'react'
import { Cookies } from 'typescript-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { setAuthorizationStatus, setToken, setUserInfo } from '../store/slices/base-slice'
import { AuthorizationStatus, PATH } from '../libs/consts'

import Redirect from './redirect'

interface PrivateRouteProps {
    children: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(true)

    const notifySuccess = () => toast.success(t('components.loggedIn'))

    const authorizationStatus = useAppSelector(state => state.base.authorizationStatus)
    React.useEffect(() => {
        if (Cookies.get('access_token') && authorizationStatus !== AuthorizationStatus.AUTH) {
            dispatch(setAuthorizationStatus(AuthorizationStatus.AUTH))
            // dispatch(refreshToken({ refresh_token: getCookie("refresh_token") }))
            notifySuccess()
            dispatch(setToken(JSON.stringify(Cookies.get('access_token'))))
        }

        if (!Cookies.get('access_token')) {
            dispatch(setAuthorizationStatus(AuthorizationStatus.NO_AUTH))
        }

        if (location.pathname === '/auth' && Cookies.get('access_token')) {
            dispatch(setUserInfo({ access_token: JSON.stringify(Cookies.get('refresh_token')), refresh_token: JSON.stringify(Cookies.get('access_token')) }))
            navigate('/')
        }

        setIsLoading(false)
    }, [authorizationStatus])

    return (
        <>
            {!isLoading
                ? authorizationStatus === AuthorizationStatus.AUTH
                    ? <>
                        {/* <Header /> */}
                        {children}
                        {/* <Footer /> */}
                    </>
                    : <Redirect to={PATH.auth} />
                : <h1></h1>
            }
        </>
    )
}


export default React.memo(PrivateRoute)
