
import styled from 'styled-components/macro'

import { useTranslation } from 'react-i18next'
import { getCookie } from 'typescript-cookie'

import { addStatusIcon, findStatus, rounding_format } from '../../libs/utils/utils'
import Button from '../../components/button/button'
import CustomSelect from '../custom-select/CustomSelect'
import CopyableText from '../copyAbleText/copyAbleText'



const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const TransactionCard = ({
    item,
    setIsVisiblePopupAppeal,
    setSelectedId,
    sendCallback,
    activeOption,
    setIsVisiblePopupCheck,
    isVisiblePopupCheck,
    setCheckArray }) => {
  const dateCreate = new Date(item?.date_create).toLocaleString()
  const {t} = useTranslation()
  const dateComplete = item?.date_update ? new Date(item?.date_update).toLocaleString() : null
  const cryptoActions = [
    { id: 2, label: 'Апелляция', name: 'appeal' },
    { id: 3, label: 'Callback', name: 'callback'},
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', margin: '16px auto', backgroundColor: 'white', minWidth: '250px', maxWidth: '600px'}}>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ backgroundColor: addStatusIcon(item?.status), display: 'flex', padding: '5px', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: '600' }}>
          {t(`components.${findStatus(item?.status)}`)}
        </div>
      </div>
      <div>
        {getCookie('is_fiat') === 'false' && (<div>
          <strong>ID:</strong> {item?.transaction_out?.slice(0, 10)}
        </div>)}
        {getCookie('is_fiat') === 'true' && (<div>
          <strong>ID:</strong> {item?.tracker_id?.slice(0, 10)}
        </div>)}
        {item?.client_merchant_id && (
          <div>
            <strong>{t('components.clientMerchantId')}:</strong> {item?.client_merchant_id?.slice(0, 10)}
          </div>
        )}
        {item?.fingerprint && (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <strong>{t('components.fingerprint')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
            {item?.fingerprint ?
                <CopyableText text={item?.fingerprint} truncatedLength={20} isWrap={true} />
            : '-'}
          </div>
        </div>
        )}
        {item?.commission && (
          <div>
            <strong>{t('components.commission')}:</strong> {item?.commission && rounding_format(item?.commission, 2)}
          </div>
        )}
        {item?.transaction_commission && (
          <div>
            <strong>{t('components.commission')}:</strong> {item?.transaction_commission && rounding_format(item?.transaction_commission, 2)}
          </div>
        )}
        <div>
          <strong>{t('components.clientTransactionId')}:</strong> {item?.client_transaction_id?.slice(0, 40)}
        </div>
        <div>
          <strong>{t('components.requisites')}:</strong> {item?.receiver}
        </div>
        {getCookie('is_fiat') === 'false' && (<div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.send')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}> {item?.token_from === 'USDTTRC' ? (
            <span
              style={{
                color: '#D65A4A',
                fontWeight: '600',
                fontSize: '15px',
              }}
            >
              -{' '}
              {rounding_format(item?.amount_from, 2, '.', ',')}
            </span>
            ) : (
              rounding_format(item?.amount_from, 2, '.', ',')
            )}
            <span style={{ marginLeft: '4px' }}>{item?.token_from}</span>
          </div>
        </div>)}
        {getCookie('is_fiat') === 'true' && (<div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.amount')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {
              rounding_format(item?.amount, 2, '.', ',')
            }
            <span style={{ marginLeft: '4px' }}>{item?.token}</span>
          </div>
        </div>)}
        {getCookie('is_fiat') === 'false' && (<div>
          <strong>{t('components.rate')}:</strong> {rounding_format(item?.exchange_rate, 2, '.', ',')}
        </div>)}
        {getCookie('is_fiat') === 'false' && (<div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.get')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}> {item?.token_to === 'USDTTRC' ? (
            <span
              style={{
                color: '#65B92F',
                fontWeight: '600',
                fontSize: '15px',
              }}
            >
              + {rounding_format(item?.amount_to, 2, '.', ',')}
            </span>
            ) : (
              rounding_format(item?.amount_to, 2, '.', ',')
            )}
            <span style={{ marginLeft: '4px' }}>{item?.token_to}</span>
          </div>
        </div>)}
        {getCookie('is_fiat') === 'true' && (<div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.total')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {item?.type === 'IN' ? (
            <span
              style={{
                color: '#65B92F',
                fontWeight: '600',
                fontSize: '17px',
              }}
            >
              + {rounding_format(item?.total, 2, '.', ',')}
            </span>
              ) : (
                <span
                  style={{
                    color: '#D65A4A',
                    fontWeight: '600',
                    fontSize: '17px',
                  }}
                >
                  -{' '}
                  {rounding_format(item?.total, 2, '.', ',')}
                </span>
              )}
            <span style={{ marginLeft: '4px' }}>{item?.token}</span>
          </div>
        </div>)}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.dateCreate')}:</strong> <span style={{ marginLeft: '4px' }}><DateWrapper>{dateCreate}</DateWrapper></span>
        </div>
        {(item?.status === 'EXPIRED' || item?.status === 'SUCCESS' || item?.status === 'ERROR') && dateComplete && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>{t('components.dateComplete')}:</strong> 
            <span style={{ marginLeft: '4px' }}>
              <DateWrapper color={addStatusIcon(item?.status)}>
                {dateComplete}
              </DateWrapper>
            </span>
          </div>
        )}
      </div>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ marginTop: '10px'}}>
          {activeOption?.status === 'APPEAL' || item?.status === 'APPEAL' ? (
            item?.appeal_link?.length ?? 0 ? (
              <Button small={true} disabled={!item?.appeal_link?.length} onClick={() => {
                setIsVisiblePopupCheck(!isVisiblePopupCheck)
                setCheckArray(item?.appeal_link || [])
              }} variant="primary">
                {t('components.inspect')}
              </Button>
            ) : 
              null ) : (
              <div style={{ marginTop: '5px'}}>
                <CustomSelect
                  options={item?.status === 'EXPIRED' || item?.status === 'SUCCESS' || item?.status === 'ERROR' ? cryptoActions : [cryptoActions[0]]}
                  width="100%"
                  height="35px"
                  selectHandler={(e) => {
                    if(e.name === 'appeal') {
                      setIsVisiblePopupAppeal(true)
                      setSelectedId( item?.transaction_out ? item?.transaction_out : '')
                    } else {
                      sendCallback({tracker_id: item?.tracker_id || ''})
                    } 
                  }}
                  label=""
                  selectedOption={{ name: 'actions' }}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default TransactionCard
