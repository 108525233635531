import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import {useTranslation} from 'react-i18next'

import {ChevronDown} from '../../assets/images/images'
import { useClickOutside } from '../../utils/useClickOutside';

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`

const SelectContent = styled.div<{ w: string, h: string, bg: string, fetching?: boolean, loading?: boolean }>`
  padding: 6px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: ${({ bg }) => (bg ? bg : 'transparent')};
  background-color: ${({ fetching, loading }) => (fetching || loading ? 'rgba(0, 0, 0, 0.1)' : 'white')};
  width: ${({ w }) => (w ? w : '100%')};
  height: ${({ h }) => (h ? h : '100%')};
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;
  cursor: ${({ fetching, loading }) => (fetching || loading ? 'not-allowed' : 'pointer')};
  user-select: none;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
  color: #000;
  :hover {
    border-color: #fff;
  }
`

const Select = styled.div`
  /* background-color: #; */
  color: #000;
`

const Option = styled.div`
  padding: 6px 10px;
    color: #000;
    text-align: start;
  :hover {
    background: #fff;
    cursor: pointer;
    transition: all 0.3s ease 0s;
  }
`

interface IChevronWrapperProps {
    isRotate: boolean;
}
const ChevronWrapper = styled.div<IChevronWrapperProps>`
  width: 24px;
  height: 24px;
  transform: ${({ isRotate }) => isRotate ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.3s ease 0s;
  > svg {
    fill: #1b2341;
  }
`

interface IVisibleOptions {
    withLabel?: boolean,
    isCreate?: boolean
}

const VisibleOptions = styled.div<IVisibleOptions>`
  position: absolute;
  top: ${({isCreate}) => isCreate ? '-40px' : '0'};
  right: 0;
  display: flex;
    align-items: start;
  flex-direction: column;
  transform: ${({ withLabel }) => withLabel ? 'translateY(78px)' : 'translateY(38px)'};
    width: 100%;
  padding: 6px 0px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border: 1px solid #e0e0e0;
  border-radius: 5px;
  z-index: 1;
  overflow-y: scroll;
  max-height: 250px;
`

interface ICustomSelectProps {
    id?: string
    label?: string
    options: { level: string }[]  | undefined
    width: string
    height: string
    backgroundColor?: string
    selectHandler: (id: string, value: { level: string }) => void
    selectedOption?: string | number
    fetching?: boolean
    loading?: boolean
    isCreate?: boolean
}


const AccessSelect: React.FC<ICustomSelectProps> = ({
        id,
        options = [],
        width,
        height,
        backgroundColor,
        selectHandler,
        label,
        selectedOption,
        fetching = false,
        loading,
        isCreate = false
    }) => {


    const [activeOption, setActiveOption] = React.useState(!!selectedOption ? selectedOption : options?.[0]?.level)
    const [isShowOptions, setIsShowOptions] = React.useState(false)
    const {t} = useTranslation()


    React.useEffect(() => {
        const defaultOption = options?.[0]?.level
        setActiveOption(!!selectedOption ? selectedOption : defaultOption)
    }, [options, selectedOption])

    const handleChangeOption = React.useCallback((option: {level: string}) => {
        !selectedOption && setActiveOption(option.level)
        setIsShowOptions(false)
        selectHandler(id, option)
    }, [selectedOption, selectHandler])

    const handleChangeIsShowOptions = React.useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if(fetching) return
            setIsShowOptions(!isShowOptions)
            e.stopPropagation()
        },
        [isShowOptions, fetching],
    )

    const closeSelectOptions = React.useCallback(() => {
        setIsShowOptions(false)
    }, [])

    const selectWrapperRef = useRef(null)
    useClickOutside(selectWrapperRef, () => {
      closeSelectOptions()
    })

    return (
        <SelectWrapper ref={selectWrapperRef}>
              <SelectContent onClick={handleChangeIsShowOptions} bg={backgroundColor || ''} w={width} h={height}
                             fetching={fetching} loading={loading}>
                  {!!activeOption && <Select>{t(`accessLevels.${activeOption}`)}</Select>}
                  <ChevronWrapper isRotate={isShowOptions}>
                      <ChevronDown/>
                  </ChevronWrapper>
              </SelectContent>
              {isShowOptions && (
                  <VisibleOptions withLabel={!!label} isCreate={isCreate}>
                    {options?.map((op: IOption, index: number) => (
                          <Option onClick={() => handleChangeOption(op)}
                                  key={index + op.level}>{op?.level && t(`accessLevels.${op.level}`)}</Option>
                      ))}
                  </VisibleOptions>
              )}
          </SelectWrapper>
    )
}

export default React.memo(AccessSelect)

export interface IOption {
   level: string
}
