import {getCookie} from 'typescript-cookie'
import {IAccesses, ILevels, IStatus} from '../types/types'

export const PATH = {
    main: '/',
    dashboard: '/dashboard',
    // supports: '/support',
    settings: '/settings',
    auth: '/auth',
    inOut: '/withdraw',
    conversions: '/conversions',
}

export const header = [
    { name: 'main', link: '/' },
    { name: 'balance', link: '/withdraw' },
]

// todo: create api and lk consts
export const apiEndPoints = {
    GET_HISTORY: 'lk/stock/order/fetch', // История транзакций
    GET_HISTORY_CRYPTO: 'lk/swap/fetch',
    GET_TRANSACTIONS: 'lk/stock/transaction/fetch',
    GET_TRANSACTIONS_CRYPTO: 'lk/transaction/fetch',
    GET_BALANCE_HISTORY: 'api/info/history/balance', // История транзакций в балансе
    GET_USER_INFO: '/lk/user/info',

    GET_KEYPAIR: 'api/user/keypair', // Генерация ключей
    WITHDRAW: 'lk/transaction/create/withdrawal', // Вывод рубля
    CREATE_INVOICE: 'lk/transaction/create/invoice', //  Ввод крипты
    BALANCE: 'lk/stock/balance', // Баланс
    CASHFLOW: 'lk/stock/statistic', // Оборот
    GET_COURSE: 'lk/stock/course', // Курсы
    SIGN_IN: 'lk/auth/sign_in', //авторизация
    SUBUSER_LOGIN: '/lk/subuser/login', //авторизация сабюзера
    SIGN_UP: 'lk/auth/sign_up', //регистрация
    REFRESH_TOKEN: 'lk/auth/refresh_token', //рефреш токен
    LOGOUT: 'lk/auth/logout',
    UPDATE_PASSWORD: 'lk/update_password',

    APPEAL: '/report', // апелляция
    GET_REPORTS: '/reports', // получить список апелляций
    GET_CONNECTION_STATUS: '/connectionStatus',
    CREATE_APPEAL: 'lk/v2/appeal/create',
    CLOSE_APPEAL: 'lk/appeal/close',
    FETCH_APPEAL: 'lk/appeal/fetch',
    GET_APPEAL: 'lk/appeal/get',
    TOKEN_FETCH: '/token/fetch/p2p',
    TOKEN_CRYPTO_FETCH: 'lk/token/fetch/all',
    SUBTOKEN_FETCH: 'lk/payment_system/fetch',
    GET_API_KEYS: 'lk/api_keys',
    UPDATE_API_KEYS: 'lk/api_keys',
    DOWNLOAD_CSV: 'lk/transaction/fetch/csv',
    FETCH_USER_CSV_TRANSACTIONS: 'lk/report',//все запрошенные юзером выгрузки csv транзакций
    FETCH_CSV_TRANSACTIONS: 'lk/report/request',//запрос формирования выгрузки csv транзакций
    FETCH_CSV_FILE: 'lk/report/download',//скачивание файла
    FETCH_CSV: 'lk/transaction/fetch/csv',
    FETCH_BALANCE_CSV: 'lk/transaction/fetch/settlement/csv',
    GET_SESSIONS: 'lk/sessions/get',
    CLEAR_SESSIONS: 'lk/sessions/clear',
    GET_SUBUSERS: '/lk/subuser/list',
    DELETE_SUBUSERS: '/lk/subuser/delete',
    UPDATE_SUBUSER_PASSWORD: '/lk/subuser/new_password',
    UPDATE_SUBUSERS: '/lk/subuser/update',
    GET_SUBUSERINFO: 'lk/subuser/info',

    GET_CONVERSIONS: '/lk/account/conversion/fetch',
    FETCH_CSV_CONVERSIONS: '/lk/account/conversion/report',
}

export const AuthorizationStatus = {
    AUTH: 'auth',
    NO_AUTH: 'notAuth',
}

export const SERVICES = {
    LK: 'lk',
    API: 'api',
}

export const options: IStatus[] = [
    { name: 'successful', status: 'SUCCESS', id: 1 },
    { name: 'accepted', status: 'ACCEPTED', id: 2 },
    { name: 'appeal', status: 'APPEAL', id: 4 },
    { name: 'canceled', status: getCookie('is_fiat') === 'true' ? 'ERROR' : 'EXPIRED', id: 5 },
    { name: 'all', status: '', id: 3 },
]

export const LEVELS: ILevels = {
    balance_access: [
        {
            level: '0',
        },
        {
            level: '1',
        },
    ],
    statistics_access: [
        {
            level: '0',
        },
        {
            level: '1',
        },
    ],
    invoice_access: [
        {
            level: '0',
        },
        {
            level: '2',
        },
    ],
    withdrawal_access: [
        {
            level: '0',
        },
        {
            level: '2',
        },
    ],
    transactions_access: [
        {
            level: '0',
        },
        {
            level: '2',
        },
    ],
    orders_access: [
        {
            level: '0',
        },
        {
            level: '2',
        },
    ],
    tx_operations_access: [
        {
            level: '0',
        },
        {
            level: '2',
        },
    ],
    conversion_access: [
        {
            level: '0',
        },
        {
            level: '1',
        },
    ]
}

export const ACCESSES: IAccesses = {
    balance_access: 0,
    statistics_access: 0,
    invoice_access: 0,
    withdrawal_access: 0,
    transactions_access: 0,
    orders_access: 0,
    tx_operations_access: 0,
    conversion_access: 0
}
