import React from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import Button from '../../components/button/button'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import Popup from '../../components/popup/popup'
import { useFetchConversionCsvMutation } from '../../store/services/conversions-service'


const Wrapper = styled.div`
    justify-self: end;

    @media (max-width: 655px) {
        justify-self: start;
    }
`

const CsvWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 100%;
`

const CSVConversionButton = () => {
    const {t} = useTranslation()
    const [isCsvPopupVisible, setIsCsvPopupVisible] = React.useState(false)

    const [inputsScv, setInputsScv] = React.useState<any>({
        'dateFrom': new Date(),
        'dateTo': new Date(),
    })

    const [fetchCsv] = useFetchConversionCsvMutation()

    function changeInputs(e: React.ChangeEvent<HTMLInputElement>, type?: string) {
        const { name, value } = e.target
        setInputsScv((prev: any) => ({
            ...prev,
            [name]: value,
        }))
    }

    return (
        <Wrapper>
            <Button onClick={() => setIsCsvPopupVisible(true)} variant="primary">
                <span>{t('components.downLoadCSV')}</span>
            </Button>
            <Popup isVisible={isCsvPopupVisible} setIsVisible={setIsCsvPopupVisible}>
                <CsvWrapper>
                    <InputWithLabel
                        required
                        name={'dateFrom'}
                        value={inputsScv['dateFrom']}
                        label="from"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        type="date"
                        placeholder=""
                    />
                    <InputWithLabel
                        required
                        name={'dateTo'}
                        value={inputsScv['dateTo']}
                        label="to"
                        type="date"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        placeholder=""
                    />
                    <Button
                        onClick={() => {
                            const dateFrom = new Date(inputsScv['dateFrom'])
                            const dateTo = new Date(inputsScv['dateTo'])
                            fetchCsv({
                                date_from: dateFrom.toISOString(),
                                date_to: dateTo.toISOString(),
                             })
                        }}
                        variant="primary"
                    >
                        {t('components.toDownLoadCSV')}
                    </Button>
                </CsvWrapper>
            </Popup>
        </Wrapper>
    )
}

export default CSVConversionButton