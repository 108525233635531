import React from 'react'

import Container from '../../components/container/container'
import InOut from '../../features/in-out/in-out'
import TransactionsTable from '../../features/transactions-table/TransactionsTable'
import {useAppSelector} from '../../hooks/redux';

const Withdraw: React.FC = () => {

	const {transactions_access} = useAppSelector((state) => state.base.accesses)
	const isSubUser = useAppSelector((state) => state.base.isSubUser)

	return (
		<Container>
			<InOut />
			{isSubUser ? !!transactions_access &&
				<TransactionsTable/>
				:
				<TransactionsTable />
			}
		</Container>
	)
}

export default React.memo(Withdraw)
