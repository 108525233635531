import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setCookie } from 'typescript-cookie'

import baseService from '../services/base-service'
import { IBaseInitialState } from '../slices/base-slice'
import { ICourseState } from '../slices/course-slice'



interface IKeypairData {
    privileges: string[]
}



export const getKeypair = createAsyncThunk(
    'getKeypair',
    async function (data: IKeypairData, { rejectWithValue }) {
        try {
            const res = await baseService.get_keypair(data)
            return res
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const getKeypairHandler = (builder: ActionReducerMapBuilder<IBaseInitialState>) => { }




export const fetchCourse = createAsyncThunk(
    'fetchCourse',
    async function (_, { rejectWithValue }) {
        try {
            const res = await baseService.fetch_course()
            return res
        } catch (err) {
            return rejectWithValue(err)
        }
    },
)
export const fetchCourseHandler = (builder: ActionReducerMapBuilder<ICourseState>) => {
    builder.addCase(fetchCourse.fulfilled, (state, action) => {
        state.course = action.payload
    })
}






// export const authorization = createAsyncThunk(
//     'auth',
//     async function (data: { login: string; password: string }, { rejectWithValue }) {
//         try {
//             const res = await baseService.auth(data)
//             return res
//         } catch (err) {
//             return rejectWithValue(err)
//         }
//     },
// )
// export const authorizationHandler = (builder: ActionReducerMapBuilder<IBaseInitialState>) => {
//     builder.addCase(authorization.fulfilled, (state, action: PayloadAction<{ success: boolean, access_token: string, refresh_token: string, fingerkey: string }>) => {
//         state.userInfo.success = action.payload.success
//         state.userInfo.access_token = action.payload.access_token
//         state.userInfo.refresh_token = action.payload.refresh_token
//         state.fingerkey = action.payload.fingerkey

//         setCookie('access_token', action.payload.access_token, { expires: 7 })
//         setCookie('refresh_token', action.payload.refresh_token, { expires: 1 })
//         setCookie('fingerkey', action.payload.fingerkey, { expires: 365 })
//     },
//     )
// }







// export const registration = createAsyncThunk(
//     'registration',
//     async function (data: { login: string; password: string, invite_code: string }, { rejectWithValue }) {
//         try {
//             const res = await baseService.signup(data)
//             return res
//         } catch (err) {
//             return rejectWithValue(err)
//         }
//     },
// )
// export const registrationHandler = (builder: ActionReducerMapBuilder<IBaseInitialState>) => {
//     builder.addCase(registration.fulfilled, (state, action: PayloadAction<{ success: boolean, access_token: string, refresh_token: string }>) => {
//         state.userInfo.success = action.payload.success
//         state.userInfo.access_token = action.payload.access_token
//         state.userInfo.refresh_token = action.payload.refresh_token
//     })
// }







// export const refreshToken = createAsyncThunk(
//     'refreshToken',
//     async function (data: { refresh_token: string | undefined }, { rejectWithValue }) {
//         try {
//             const res = await baseService.refresh_token(data)
//             return res
//         } catch (err) {
//             rejectWithValue(err)
//         }
//     },
// )
// export const refreshTokenHandler = (builder: ActionReducerMapBuilder<IBaseInitialState>) => {
//     builder.addCase(refreshToken.fulfilled, (state, action: PayloadAction<{ success: boolean, access_token: string, refresh_token: string }>) => {
//         state.userInfo = action.payload
//         setCookie('access_token', action.payload.access_token, { expires: 7 })
//     })
// }





export const getConnectionStatus = createAsyncThunk(
    'getConnectionStatus',
    async function (_, { rejectWithValue }) {
        try {
            const res = await baseService.get_connection_status()
            return res
        } catch (err) {
            rejectWithValue(err)
        }
    },
)
export const getConnectionStatusHandler = (builder: ActionReducerMapBuilder<IBaseInitialState>) => {
    builder.addCase(getConnectionStatus.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.connectionStatus = action.payload
    })
}
