import React from 'react'
import { NavLink, Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import logoImg from '../../assets/images/logo.svg'
import { useAppSelector } from '../../hooks/redux';
import { AuthorizationStatus } from '../../libs/consts';



const NotExistWrapper = styled.div`
    max-width: 1440px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const LogoContent = styled.div`
	display: flex;
    margin: 20px 0 0 20px;
	flex-direction: row;
    align-items: end;
    cursor: pointer;
`

const Logo = styled.div`
	text-transform: uppercase;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	display: flex;
	align-items: center;
	gap: 7px;
	
`

const LogoText = styled.div`
`


const LogoTitle = styled.h1`
	display: flex;
	font-family: 'Audiowide';
	font-size: 35px;
	line-height: 100%;
	padding-left: auto;	
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #000;
`

const LogoSubTitle = styled.h2`
	font-style: normal;
	font-weight: 500;
    width: 140px;
	font-size: 12px;
    line-height: 100%;
	letter-spacing: 0.395em;
	text-transform: uppercase;
	color: #BDBDBF;
`

const MainContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;   
    margin: 13% 30%;

    @media screen and (max-width: 1150px) {
        margin: 15% 20% 0 25%; 
    }

    @media screen and (max-width: 1024px) {
        margin: 15% 20% 0 20%;
        font-size: 30px;  
    }

    @media screen and (max-width: 840px) {
        margin: 20% 15% 0 10%;
        font-size: 30px;  
    }

    @media screen and (max-width: 768px) {
        margin: 30% 15% 0 0%;
        font-size: 35px;  
    }
    @media screen and (max-width: 600px) {
        margin: 37% 0% 0 4%;
        font-size: 30px;  
    }
    @media screen and (max-width: 420px) {
        margin: 50% 0% 0 4%;
        font-size: 25px; 
    }
`


const Title = styled.div`
    display: flex;
    width: 530px;
    align-items: center;
    font-size: 40px;    
    font-weight: 800;
    line-height: 140%;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 30px;  
    }

    @media screen and (max-width: 420px) {
        width: 100%;
        padding-left: 20px;
        font-size: 25px;  
    }

`

const SubTitle = styled.div`
    display: flex;
    flex-wrap: wrap;    
    width: 500px;
    align-items: center;
    font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 140%;
	letter-spacing: 0.1em;
    margin-top: 12px;
	color: #BDBDBF;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 20px;  
    }
    @media screen and (max-width: 420px) {
        width: 100%;
        padding-left: 20px;
        font-size: 19px;  
    }
`

const LinkToMain = styled.p`
    display: flex;
    color: #7154DC;
    text-decoration: underline;
    cursor: pointer;
`

const NotExist = () => {

    const { authorizationStatus } = useAppSelector(state => state.base)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const back = () => {
        authorizationStatus === AuthorizationStatus.AUTH ? navigate('/') : navigate('/auth')
    }

    return (
        <>
            <NotExistWrapper>
                <LogoContent onClick={back}>
                    <Logo>
                        <img src={logoImg} alt="logo" style={{
                            width: '70px',
                            height: '70px',
                            marginRight: '10px',
                        }} />
                    </Logo>
                    <LogoText>
                        <LogoTitle>EXPAY</LogoTitle>
                        <LogoSubTitle>universe of payments</LogoSubTitle>
                    </LogoText>
                </LogoContent>
                <MainContent>
                    <Title >
                        {`${t('components.sorry')}, ${t('components.notExist')}`}
                    </Title>
                    <SubTitle >
                        {t('components.goToOur')}
                        <LinkToMain onClick={back}>
                            {t('components.mainPage')}
                        </LinkToMain>
                    </SubTitle>
                </MainContent>

            </NotExistWrapper>
        </>
    )
}

export default React.memo(NotExist)