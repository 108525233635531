import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { addStatusIcon, findStatus, rounding_format } from '../../libs/utils/utils'
import CopyableText from '../copyAbleText/copyAbleText'

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const BalanceTableCard = ({ item }) => {
  const dateCreate = new Date(item?.date_create).toLocaleString()
  const dateComplete = item?.date_update ? new Date(item?.date_update).toLocaleString() : null
  const {t} = useTranslation()

  const typeName = {
	IN: 'in',
	INVOICE: 'in',
	WITHDRAW: 'out',
	OUT: 'out',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', margin: '16px auto', backgroundColor: 'white', minWidth: '250px', maxWidth: '600px'}}>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ backgroundColor: addStatusIcon(item?.status), display: 'flex', padding: '5px', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: '600' }}>
          {t(`components.${findStatus(item?.status)}`)}
        </div>
      </div>
      <div>
        <div>
          <strong>{t('components.type')}:</strong> {t(`components.${typeName[item.type]}`)}
        </div>
        <div>
          <strong>ID:</strong> { item.tracker_id?.slice(0, 10) || '-'}
        </div>
        {item.receiver && (
          <div style={{ display: 'flex', alignItems: 'start'}}>
            <strong>{t('components.adress')}:</strong>
            <div style={{ marginLeft: '4px', display: 'flex', alignItems: 'center', wordBreak: 'break-all', color: '#7154DC', fontWeight: '600', fontSize: '14px' }}>
              <CopyableText text={item.receiver} isFull={true} isWrap={true} color="#7154DC" />
            </div>
          </div>
        )}
        {item.hash && (
          <div style={{ display: 'flex', alignItems: 'start'}}>
            <strong>{t('components.hash')}:</strong> 
            <div style={{ marginLeft: '4px', display: 'flex', alignItems: 'center', wordBreak: 'break-all', fontSize: '14px' }}>
              <CopyableText text={item.hash} isFull={true} />
            </div>
          </div>
        )}
        <div>
          <strong>{t('components.commission')}:</strong> {item?.transaction_commission === null ? '-' : item?.transaction_commission.toFixed(2) || '-'}
        </div>
        <div style={{ display: 'flex', alignItems: 'start'}}>
          <strong>{t('components.amount')}:</strong>
          <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', overflow: 'auto', flexWrap: 'wrap' }}>
            {
            	item.type === 'IN' ?
            		<span style={{ color: '#65B92F', fontWeight: '600', fontSize: '15px' }}>
            			{item.amount ? window.innerWidth <= 380 ? `+ ${rounding_format(item.amount, 2, '.', ',')}` : `+ ${item.amount}` : null}
            		</span> :
            		<span style={{ color: '#D65A4A', fontWeight: '600', fontSize: '15px' }}>
            			{item.amount ? `- ${rounding_format(item.amount, 2, '.', ',')}` : null}
            		</span>
            }
			      <span style={{ marginLeft: '4px' }}>{item.token}</span>
		      </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.dateCreate')}:</strong> <span style={{ marginLeft: '4px' }}><DateWrapper>{dateCreate}</DateWrapper></span>
        </div>
        {(item?.status === 'ERROR' || item?.status === 'SUCCESS') && dateComplete && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <strong>{t('components.dateComplete')}:</strong> 
            <span style={{ marginLeft: '4px' }}>
              <DateWrapper color={addStatusIcon(item?.status)}>
                {dateComplete}
              </DateWrapper>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default BalanceTableCard
