import React from 'react'
import styled from 'styled-components'

import PaginationButton from './pagination-button'

const PaginationWrapper = styled.div`

	display: flex;
	align-items: center;
	gap: 0.5rem;
`

const maxSlicePage = 5

interface IPaginationProps {
	total: number | undefined
	page: number | undefined
	offset: number
	limit: number
	onPageChange: (num: number, offset: number) => void
	fetching?: boolean
	loading?: boolean
}

const Pagination: React.FC<IPaginationProps> = ({
	total = 0,
	page = 1,
	offset = 1,
	limit = 5,
	onPageChange,
	fetching,
	loading,
}) => {
	const howManyButtonsOnSides = 1

	const slicedPages = React.useMemo(() => {
		if (page < 2) {
			if (total < limit) {
				return []
			}
			const arr = [...new Array(2).keys()]
			const newArr = [] as number[]
			arr.map((el, indx) => {
				if (indx !== 0 && indx !== arr.length) {
					newArr.push(el + 1)
				}
			})
			return newArr
		} else {
			if (total && total < limit) {
				return [page - 1, page].filter((item) => item !== 1)
			}
		}
		return [page - 1, page, page + 1].filter((item) => item !== 1)
	}, [page, total, limit])

	return (
		<PaginationWrapper>
			{!!total ?
				<PaginationButton
					onClick={() => onPageChange(1, offset)}
					active={page === 1}
				>
					{1}
				</PaginationButton>
				:
				null}
			{slicedPages.map((num: number, indx: number) => {
				if (num - 1 > howManyButtonsOnSides && !indx) {
					return (
						<React.Fragment key={indx}>
							<span>...</span>
							<PaginationButton
								onClick={() => onPageChange(num, offset)}
								active={page === num}
								key={num}
							>
								{num}
							</PaginationButton>
						</React.Fragment>
					)
				} else {
					return (
						<PaginationButton
							onClick={() => onPageChange(num, offset)}
							active={page === num}
							key={num}
							disabled={fetching || loading}
						>
							{num}
						</PaginationButton>
					)
				}
			})}
		</PaginationWrapper>
	)
}


export default React.memo(Pagination)
