import {Typography} from 'antd'

import React, {memo, useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {toast} from 'react-toastify';

import InputWithLabel from '../input-with-label/input-with-label'


import AccessSelect from '../access-select/AccessSelect'
import Button from '../button/button'
import Popup from '../popup/popup'
import {GetPasswordError} from '../../utils/getPasswordError'
import DefaultSelect from '../default-select/DefaultSelect'
import {ACCESSES, LEVELS} from '../../libs/consts'
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import { useGetSubUserInfoQuery, useUpdateSubUserMutation, useUpdateSubUserPasswordMutation } from '../../store/services/subusers-service';
import { getCookie } from 'typescript-cookie';
import { setSubUserData } from '../../store/slices/base-slice';

interface Props {
    editSubuserOpen: boolean;
    setEditSubuserOpen: (open: boolean) => void;
}

const EditSubUserModal = ({editSubuserOpen, setEditSubuserOpen}: Props) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch();

    const [subuserLogin, setSubuserLogin] = React.useState('')
    const [subuserPassword, setSubuserPassword] = React.useState('')
    const [subuserOtp, setSubuserOtp] = React.useState('')
    const [passwordError, setPasswordError] = React.useState<string | null>(null)

    const [accesses, setAccesses] = React.useState(ACCESSES)

    const {id} = useAppSelector((state) => state.base.subuserData)

    const {data: subUserInfo, isLoading, isFetching, refetch} = useGetSubUserInfoQuery({id: id})

    const [updateSubUser] = useUpdateSubUserMutation()
    const [updatePassword] = useUpdateSubUserPasswordMutation()

    const handleSelectAccess = (id: string, value: { level: string }) => {
        setAccesses((prev: any) => ({...prev, [id]: Number(value.level)}))
    }

    const handleUpdateSubuser = () => {

        if (subuserPassword.length) {
            updatePassword({
                id,
                newPassword: subuserPassword,
                otp: subuserOtp,
            })
                .then((res) => {
                    // @ts-ignore
                    if (res?.error?.originalStatus !== 200) {
                        // @ts-ignore
                        toast.error(res?.error?.data?.description)
                    } else {
                        toast.success('Password updated successfully')
                    }
                })
                .catch(() => toast.error('unknown error'))
        }
        updateSubUser({
            id,
            email: subuserLogin,
            otp: subuserOtp,
            accesses,
        })
            .then((res) => {
                // @ts-ignore
                if (res?.error?.originalStatus !== 200) {
                    // @ts-ignore
                    toast.error(res?.error?.data?.description)
                } else {
                    toast.success('Subuser updated successfully')
                    setEditSubuserOpen(false)
                }
            })
            .catch(() => toast.error('unknown error'))
    }

    useEffect(() => {
        refetch()
        Object.entries(accesses).map((access) => {
            setAccesses((prev) => ({...prev, [access[0]]: subUserInfo?.accesses[access[0]]}))
        })
        setSubuserLogin(subUserInfo?.email)
    }, [subUserInfo])

    return (
        <Popup isVisible={editSubuserOpen} setIsVisible={setEditSubuserOpen} maxHeight="90vh">
            <div style={{
                minWidth: '540px'
            }}>
                <Typography style={{
                    fontSize: '18px',
                    color: '#000',
                    fontWeight: '600',
                    textAlign: 'start',
                }}>
                    {t('components.updateSubuser')}
                </Typography>
                <InputWithLabel
                    required
                    value={subuserLogin}
                    label="login"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setSubuserLogin(e.target.value)}
                    placeholder="Login"
                />
                <InputWithLabel
                    required
                    name={'password'}
                    value={subuserPassword}
                    label="password"
                    error={passwordError || ''}
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSubuserPassword(e.target.value)
                        setPasswordError(null)
                        const {error} = GetPasswordError(e.target.value)
                        setPasswordError(error)
                    }}
                    placeholder={t("components.leaveEmpty")}
                    type="password"
                />
                <InputWithLabel
                    required
                    value={subuserOtp}
                    label="twofa"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setSubuserOtp(e.target.value)}
                    placeholder="2FA"
                />
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    gap: '12px',
                    marginTop: '16px',
                }}>
                    {accesses && subUserInfo && Object.entries(accesses).map(([key, value]: [string, number], idx) => {
                        if(getCookie('is_fiat') === 'false' && key === 'conversion_access') {
                            return
                        }
                        return (
                            <div key={key} style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                background: idx % 2 ? '#fff' : '#F7F7F7',
                                gap: '16px',
                            }}>
                                <Typography style={{
                                    fontSize: '18px',
                                    color: '#000',
                                    fontWeight: '600',
                                    textAlign: 'start',
                                }}>
                                    {t(`components.${key}`)}
                                </Typography>
                                <AccessSelect
                                    options={LEVELS[key]}
                                    id={key}
                                    width="max-content"
                                    height="34px"
                                    selectHandler={handleSelectAccess}
                                    selectedOption={value}
                                />
                            </div>
                        )
                    })}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '16px',
                }}>
                    <Button w="max-content" variant="primary"
                            disabled={!subuserLogin || !subuserOtp || subuserOtp.length < 6 || !Number(subuserOtp)}
                            onClick={() => handleUpdateSubuser()}>
                        {t('components.update')}
                    </Button>
                </div>
            </div>
        </Popup>
    )
}

export default EditSubUserModal;
