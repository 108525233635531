import {
	createApi,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie, setCookie } from 'typescript-cookie'

import { BASE_API_URL, BASE_API_URL_CRYPTO } from '../../libs/utils/api'

import { TTokensAll } from '../../types/types'

import { apiEndPoints } from '../../libs/consts'

import { IOption } from '../../components/custom-select/CustomSelect'

import { secureQueryBuilder } from './_api'

interface ISubTokenResponse {
	count: number
	payment_systems: any
}

const secureQuery = secureQueryBuilder(`${BASE_API_URL}`)
const secureQuery2 = secureQueryBuilder(`${BASE_API_URL_CRYPTO}`)

export const tokensApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'tokensApi',
	tagTypes: ['Tokens'],
	endpoints: (
		build: EndpointBuilder<
			BaseQueryFn<
				string | FetchArgs,
				any,
				FetchBaseQueryError,
				{},
				FetchBaseQueryMeta
			>,
			'Tokens',
			'tokensApi'
		>,
	) => ({
		tokenFetch: build.query<TTokensAll, null>({
			query() {
				return {
					url: `${apiEndPoints.TOKEN_FETCH}`,
					method: 'GET',
				}
			},
			transformResponse: (response: TTokensAll) => response,
			providesTags: () => [{ type: 'Tokens', id: 'ID' }],
		}),
		subTokenFetch: build.query<ISubTokenResponse, string>({
			query(token) {
				return {
					url: `${apiEndPoints.SUBTOKEN_FETCH}?token=${token}`,
					method: 'GET',
				}
			},
			transformResponse: (response: ISubTokenResponse) => response,
			providesTags: () => [{ type: 'Tokens', id: 'ID' }],
		}),
	}),
})

export const {
	useTokenFetchQuery,
	useLazySubTokenFetchQuery,
} = tokensApi

export const tokensCryptoApi = createApi({
	baseQuery: secureQuery2,
	reducerPath: 'tokensCryptoApi',
	tagTypes: ['Tokens'],
	endpoints: (
		build: EndpointBuilder<
			BaseQueryFn<
				string | FetchArgs,
				any,
				FetchBaseQueryError,
				{},
				FetchBaseQueryMeta
			>,
			'Tokens',
			'tokensCryptoApi'
		>,
	) => ({
		tokenCryptoFetch: build.query<TTokensAll, null>({
			query() {
				return {
					url: `${apiEndPoints.TOKEN_CRYPTO_FETCH}`,
					method: 'GET',
				}
			},
			transformResponse: (response: TTokensAll) => response,
			providesTags: () => [{ type: 'Tokens', id: 'ID' }],
		}),
	}),
})

export const {
	useTokenCryptoFetchQuery,
} = tokensCryptoApi

