import {Typography} from 'antd';
import {useTranslation} from 'react-i18next';

const NoAccess = () => {

    const {t} = useTranslation();


    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <Typography style={{
            color: 'black',
            fontSize: '32px',
            fontWeight: '600',
        }}>
            {t('components.noAccess')}
        </Typography>
    </div>
}

export default NoAccess
