export const GetPasswordError = (password: string): { error: string | null } => {
    const hasSpaces = /\s/
    if (hasSpaces.test(password)) {
        return {error: 'notSpaces'}
    }

    if (password.length === 0) {
        return {error: null}
    }

    if (password.length < 6) {
        return {error: 'passwordLength'}
    }

    const hasUppercase = /[A-Z]/
    const hasLowercase = /[a-z]/
    const hasDigit = /\d/
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+/

    if (!(hasUppercase.test(password) && hasLowercase.test(password) && hasDigit.test(password) && hasSpecial.test(password))) {
        return {error: 'passwordMustContain'}
    }

    return {error: null}
}
