import { useTranslation } from 'react-i18next'

import styled from 'styled-components/macro'

const DateWrapper = styled.div`
  font-size: 17px;
`

const SettingsCard = ({ item }) => {
  const date = new Date(item?.sign_time).toLocaleString()
  const {t} = useTranslation()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', margin: '16px auto', backgroundColor: 'white', minWidth: '250px', maxWidth: '400px'}}>
      <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <div style={{ backgroundColor: item.status_id === 'EXPIRED' ? 'grey' : 'green', display: 'flex', padding: '5px', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: '600' }}>
          {item.status_id}
        </div>
      </div>
      <div>
        <div>
          <strong>{t('components.ip')}:</strong> {item.ip}
        </div>
        <div>
          <strong>{t('components.userAgent')}:</strong> { item.user_agent}
        </div>
        <div>
          <strong>{t('components.city')}:</strong> { item.city}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <strong>{t('components.signTime')}:</strong> <span style={{ marginLeft: '4px' }}><DateWrapper>{date}</DateWrapper></span>
        </div>
      </div>
    </div>
  )
}

export default SettingsCard
