import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { ICash } from '../../types/types'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(BASE_API_URL)

export const cashflowApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'cashflowApi',
	tagTypes: ['Cashflow'],
	endpoints:
		(build:
			EndpointBuilder<BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
				'Cashflow', 'cashflowApi'>,
		) => ({
			getCashflow: build.query<ICash | undefined, string>({
				query(token) {
					return {
						url: `${apiEndPoints.CASHFLOW}?token=${token}`,
						method: 'GET',
					}
				},
				transformResponse: (response: ICash) => response,
				providesTags: () => [{ type: 'Cashflow', id: 'ID' }],
			}),
		}),
})

export const {
	useGetCashflowQuery,
} = cashflowApi
