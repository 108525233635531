import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

import { TBalanceHistory } from '../../types/balance'

// import { getHistoryHandler } from '../actions/history-actions'


export interface IHistory {
    history: TBalanceHistory[],
}

const initialState: IHistory = {
    history: [],
}

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {

    },
    extraReducers: (builder: ActionReducerMapBuilder<IHistory>) => {
        // getHistoryHandler(builder)
    },
})

export const {
} = historySlice.actions


export default historySlice.reducer
