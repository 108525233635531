import React, {memo} from 'react'
import {useTranslation} from 'react-i18next'

import {toast} from 'react-toastify';

import InputWithLabel from '../input-with-label/input-with-label'
import Button from '../button/button'
import Popup from '../popup/popup'
import {useAppSelector} from '../../hooks/redux';
import {Typography} from "antd";
import { useDeleteSubUserMutation } from '../../store/services/subusers-service';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const DeleteSubUserModal = memo(({open, setOpen}: Props) => {
    const {t} = useTranslation()

    const [subuserOtp, setSubuserOtp] = React.useState('')

    const [deleteSubuser] = useDeleteSubUserMutation()

    const {id} = useAppSelector(((state) => state.base.subuserData))


    const handleDeleteSubUser = () => {
        deleteSubuser({id, otp: subuserOtp})
            .then((res) => {
                // @ts-ignore
                if (res?.error?.originalStatus !== 200) {
                    // @ts-ignore
                    toast.error(res?.error?.data?.description)
                } else {
                    toast.success(t('components.success'))
                    setOpen(false)
                }
            })
            .catch(() => toast.error('unknown error'))
    }


    return (
        <Popup isVisible={open} setIsVisible={setOpen}>
            <div>
                <Typography style={{
                    fontSize: '18px',
                    color: '#000',
                    fontWeight: '600',
                    textAlign: 'start',
                }}>
                    {t('components.deleteSubUser')}
                </Typography>
                <InputWithLabel
                    required
                    value={subuserOtp}
                    label="twofa"
                    onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setSubuserOtp(e.target.value)}
                    placeholder="2FA"
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '16px',
                }}>
                    <Button w="max-content" variant="primary"
                            disabled={!subuserOtp || subuserOtp.length < 6}
                            onClick={handleDeleteSubUser}>
                        {t('components.delete')}
                    </Button>
                </div>
            </div>
        </Popup>
    )
})

export default DeleteSubUserModal;
