import React from 'react'
import styled from 'styled-components/macro'

const Container = styled.div`
  width: 100%;
  padding: 0 30px 120px;
  box-sizing: border-box;
  margin: 0 auto;
  height: 100%;
  flex: 1 1 auto;
  width: 100%;

  @media screen and (max-width: 300px) {
		padding: 0;
	}
`

export default React.memo(Container)
