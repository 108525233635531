import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { rounding_format } from '../../libs/utils/utils'

// import { ICash } from '../../store/slices/balance-slice'
import { ICourse } from '../../store/slices/course-slice'
import { IBalance } from '../../types/balance'

import balance from '../../assets/images/balance.png'
import { useAppSelector } from '../../hooks/redux'

interface IWrapperProps {
	bg: string;
}
const Wrapper = styled.div<IWrapperProps>`
	flex: 1 1 auto;
	padding: 16px 10px;
	max-width: 600px;
	min-width: 450px;
	height: 175px;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border: 1px solid #e0e0e0;

	@media screen and (max-width: 768px) {
  		padding: 10px;
	}

	@media screen and (max-width: 460px) {
  		padding: 5px;
  		border: none;
		min-width: 330px;
	}
`

const Content = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	text-transform: uppercase;
	padding: 0px 10px;
	margin-bottom: 8px;
`

const CurrencyTabs = styled.div`
	display: flex;
	align-items: start;
	justify-content: space-between;
	gap: 4px;
	margin-bottom: 2px;
`

const Balance = styled.div`
	color: #000;
	font-weight: 700;
	font-size: 16px;
	line-height: 140%;

	@media screen and (max-width: 768px) {
		font-size: 13px;
	}
`

const Currency = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	color: #000;

	@media screen and (max-width: 768px) {
		font-size: 13px;
	}
	
	@media screen and (max-width: 415px) {
		font-size: 10px;
	}
`

const OverallBalance = styled.div`
	font-weight: 500;
	font-size: 32px;
	line-height: 140%;
	color: #000;
	margin: 8px 0px 0px 0px;

	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
`

const FreezeBalance = styled.div`
	font-weight: 500;
	font-size: 32px;
	line-height: 140%;
	color: #000;
	margin: 8px 0px 0px 0px;

	@media screen and (max-width: 768px) {
		font-size: 20px;
	}
`


interface IProgitBalance {
	bg: string;
	borderColor?: string;
}
const ProfitBalance = styled.div<IProgitBalance>`
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	color: #989A9E;

	@media screen and (max-width: 768px) {
		font-size: 12px;
	}
`

const Freeze = styled.div<IProgitBalance>`
	font-weight: 500;
	font-size: 14px;
	text-transform: none;
	line-height: 140%;
	color: #989A9E;

	@media screen and (max-width: 768px) {
		font-size: 12px;
	}

	@media screen and (max-width: 320px) {
		font-size: 10px;
	}
`

interface ISelectedCur {
	active?: boolean;
}
const SelectedCur = styled.div<ISelectedCur>`
	color: ${({ active }) => (active ? '#7154DC' : '#989A9E')};
	font-weight: ${({ active }) => (active ? '700' : '400')};
	transition: all 0.3s ease 0s;
	:hover {
		cursor: pointer;
	}

	${({ active }) => {
		switch (active) {
			case true: {
				return {
					color: '#7154DC',
				}
			}
			case false: {
				return {
					color: '#989A9E',
				}
			}
		}
	}}

`

// interface IBalance {
//   currency: string
//   amount: number
//   price: number
// }

const StyledSpan = styled.span`
	color: #fff;
	padding: 0 8px;

	@media screen and (max-width: 768px) {
		padding: 0 0;
	}	
`

interface IBalanceCardProps {
	values: any;
	profitBalance?: number[] | string[];
	label: string;
	bg: string;
	arr: IBalance[] | ICourse[] | any;
	borderColor?: string;
	icon: typeof balance,
	freeze: any;
}

const BalanceCard: React.FC<IBalanceCardProps> = ({
	values = [],
	profitBalance,
	label,
	bg,
	arr = [],
	borderColor,
	icon,
	freeze,
}) => {
	const windowWidth = window.innerWidth
	const [selectedIndex, setSelectedIndex] = React.useState<number>(0)
	//@ts-ignore
	const {t} = useTranslation()
	// const [value, setValue] = React.useState(arr[0].currency)

	// console.log("qqq", values, "value", value);
	const { token } = useAppSelector(state => state.currency)

	return (
		<Wrapper bg={bg}>
			<Content>
				<CurrencyTabs>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
						<Balance> {t(`components.${label}`)} </Balance>
						<Currency>
							{!token?.token && (
								<SelectedCur
									active={true}
								>
									{t('components.USDT')}
								</SelectedCur>
							)}
							{arr.length && token?.token && (
								arr.map((val: any, index: number) => {
									return (
										//@ts-ignore
										<React.Fragment key={index + label}>
											<SelectedCur
												onClick={() => {
													// console.log("onclick val", val);
													setSelectedIndex(index)
													// setValue(val.currency)
												}}
												active={selectedIndex === index}
											>
												{t(`components.${val.currency}`)}
											</SelectedCur>
											{arr.length > 1 && index === 0 && (
												<StyledSpan>|</StyledSpan>
											)}
										</React.Fragment>
									)
								}))}
						</Currency>
					</div>
					{windowWidth < 340 ? null : <img style={windowWidth <= 768 ? { width: '30px', height: '30px' } : {}} src={icon} alt="icon" />}
				</CurrencyTabs>
				<div style={{ display: 'flex', alignItems: 'end', gap: 16 }}>
				{profitBalance && (
					<div style={{
						display: 'flex',
						alignItems: 'end',
						flexDirection: 'row',
						gap: 16,
					}}>
						<OverallBalance>
							{rounding_format(values[selectedIndex], 2, '.', ',') || 0}
						</OverallBalance>
						<ProfitBalance borderColor={borderColor} bg={bg}>
							{t('components.today')}
						</ProfitBalance>
					</div>
					
				)}
				{!profitBalance && (
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}>
						<OverallBalance>
							{rounding_format(values[!token?.token ? 0 : selectedIndex], 2, '.', ',') || 0}
							{label === 'balance' ? 
							<Freeze borderColor={borderColor} bg={bg}>
								{t('components.available')}
							</Freeze>
							:
							null
							}
						</OverallBalance>
						{label === 'balance' ? 
						<FreezeBalance>
							{freeze ? rounding_format(freeze[!token?.token ? 0 : selectedIndex], 2, '.', ',') : 0}
							<Freeze borderColor={borderColor} bg={bg}>
								{t('components.freeze')}
							</Freeze>
						</FreezeBalance>
						: null}
					</div>
				
				)}
				</div>
			</Content>
		</Wrapper>
	)
}

export default React.memo(BalanceCard)
