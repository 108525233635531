import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { ICourse } from '../../types/types'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(BASE_API_URL)

export const courseApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'courseApi',
	tagTypes: ['Course'],
	endpoints:
		(build:
			EndpointBuilder<BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
				'Course', 'courseApi'>,
		) => ({
			getCourse: build.query<ICourse, string>({
				query(token) {
					return {
						url: `${apiEndPoints.GET_COURSE}?token=${token}`,
						method: 'GET',
					}
				},
				transformResponse: (response: ICourse) => response,
				providesTags: () => [{ type: 'Course', id: 'ID' }],
			}),
			getCourseM: build.mutation<ICourse, string>({
				query(token) {
					return {
						url: `${apiEndPoints.GET_COURSE}?token=${token}`,
						method: 'GET',
					}
				},
				transformResponse: (response: ICourse) => response,
				invalidatesTags: () => [{ type: 'Course', id: 'ID' }],
			}),
		}),
})

export const {
	useGetCourseQuery,
	useGetCourseMMutation,
} = courseApi
