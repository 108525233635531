import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

import { TReport } from '../../types/reports'
import { appealHandler, getReportsHandler } from '../actions/appeals-actions'


export interface IReportsState {
    reports: TReport[]
    isSuccessNewAppeal: boolean
}

const initialState: IReportsState = {
    reports: [],
    isSuccessNewAppeal: false,
}

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {

    },
    extraReducers: (builder: ActionReducerMapBuilder<IReportsState>) => {
        getReportsHandler(builder)
        appealHandler(builder)
    },
})

export const {
} = reportsSlice.actions


export default reportsSlice.reducer
