import React, {ReactNode, useEffect, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import styled from 'styled-components/macro'
import {ToastContainer} from 'react-toastify'
import {getCookie} from 'typescript-cookie'
import 'react-toastify/dist/ReactToastify.css'

import {useDispatch} from 'react-redux';

import PrivateRoute from './hocks/private-route'
import Header from './components/header/header'
import {useAppSelector} from './hooks/redux'
import {AuthorizationStatus, PATH} from './libs/consts'

import NotExist from './pages/not-exist/NotExist'
import Conversions from './pages/conversions/Conversions'
import {useGetUserInfoQuery} from './store/services/base-service'


import {setAccesses, setIsSubUser} from './store/slices/base-slice';
import MainRouter from './pages/main/MainRouter';
import Settings from './pages/settings/Settings';
import Auth from './pages/auth/auth';
import Withdraw from './pages/withdraw/withdraw';
import NoAccess from './pages/noAccess/NoAccess';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
`

function App() {
    const dispatch = useDispatch()

    const {authorizationStatus} = useAppSelector(state => state.base)
    const {
        transactions_access,
        invoice_access,
        withdrawal_access,
        tx_operations_access,
        balance_access,
        statistics_access,
        conversion_access,
        orders_access
    } = useAppSelector((state) => state.base.accesses)
    const isSubUser = useAppSelector((state) => state.base.isSubUser)

    const [shouldFetch, setShouldFetch] = useState(false);

  const { data, isLoading, refetch } = useGetUserInfoQuery(null, {
    pollingInterval: 15000,
    skip: !shouldFetch,
  });

  const getElement = (children: ReactNode) => (
    <PrivateRoute>{children}</PrivateRoute>
  );

  useEffect(() => {
    if (authorizationStatus === "auth") {
      setShouldFetch(true);
      if (data?.accesses) {
        console.log("test");
        dispatch(setAccesses(data?.accesses));
        dispatch(setIsSubUser(true));
      }
    }
  }, [data?.accesses, authorizationStatus]);

  useEffect(() => {
    if (shouldFetch) {
      refetch();
    }
  }, [shouldFetch]);

  return (
    <>
        <Wrapper>
          {
            authorizationStatus === AuthorizationStatus.AUTH && <Header />
          }
            {!isLoading &&
                <Routes>
                    {isSubUser ?
                        <>
                            {(tx_operations_access || balance_access || statistics_access || orders_access) &&
                                <Route path="/" element={getElement(<MainRouter/>)}/>
                            }
                            {(transactions_access || invoice_access || withdrawal_access) &&
                                <Route path="/balance" element={getElement(<Withdraw/>)}/>
                            }
                            {(!tx_operations_access && !balance_access && !statistics_access && !transactions_access && !invoice_access && !withdrawal_access) &&
                                <Route path="*" element={<NoAccess/>}/>
                            }
                            {(getCookie('is_fiat') === 'true' && !!conversion_access) && (
                              <Route
                                key="875847"
                                path={PATH.conversions}
                                element={<PrivateRoute><Conversions/></PrivateRoute>}
                              />
                            )}
                        </>
                        :
                        <>
                            <Route path="/balance" element={getElement(<Withdraw/>)}/>
                            <Route path="/settings" element={getElement(<Settings/>)}/>
                            <Route path="/" element={getElement(<MainRouter/>)}/>
                            {getCookie('is_fiat') === 'true' && (
                              <Route
                                key="875847"
                                path={PATH.conversions}
                                element={<PrivateRoute><Conversions/></PrivateRoute>}
                              />
                            )}
                            <Route path="*" element={<NotExist/>}/>
                        </>
                    }
                    <Route path="/auth" element={getElement(<Auth/>)}/>
                    
                    {/*<Route path="*" element={<NotExist/>}/>*/}
                </Routes>}
        </Wrapper>
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} theme="colored" />
      </>
  )
};

export default React.memo(App)
