import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
//@ts-ignore
import { FormData } from '@multipart/form-data'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'

import { apiEndPoints } from '../../libs/consts'

import { secureQueryBuilder } from './_api'

interface IUserReport {
  message: string
  files: string[]
}

interface IReport {
  status_id: number
  external_tx_id: string
  external_order_id: string
  create_time: string
  client: null | IUserReport
  trader: null | IUserReport
}

interface IOrder {
  benefit: number
  create_date: string
  creator_amount: number
  creator_transaction_id: string
  direction_type: string
  executor_amount: number
  extra_pay_info: string
  has_appeals: boolean
  has_receipt: boolean
  internal_id: string
  payment_details_address: string
  payment_details_extra: string
  raw_rate: number
  real_rate: number
  status: string
  token_from: string
  token_to: string
}

interface IAppeal {
  close_time: string
  create_time: string
  internal_id: string
  is_closed: boolean
  link: string
  message: string
  order: IOrder
  type: string
  transaction_id: string
}

interface IFetchRequest {
  end_time: string
  is_closed: boolean
  limit: number
  offset: number
  order_id: string
  start_time: string
  type: string
}

interface IResult {
	count: number
  elements: IAppeal[]
}
interface IFetchResponse {
  description: string
  result: IResult
  status: string
}

const secureQuery = secureQueryBuilder(BASE_API_URL)


export const appealApi = createApi({
  baseQuery: secureQuery,
  reducerPath: 'appealApi',
  tagTypes: ['Appeal'],
  endpoints: (
    build: EndpointBuilder<
      BaseQueryFn<
        string | FetchArgs,
        IFetchResponse,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      'Appeal' | 'Callback',
      'appealApi'
    >,
  ) => ({
    fetchAppeals: build.query<IFetchResponse, IFetchRequest>({
      query(body) {
        return {
          url: apiEndPoints.FETCH_APPEAL,
          method: 'POST',
          body,
        }
      },
      transformResponse: (response: { description: string, result: IResult, status: string }) => response,
      providesTags: () => [{ type: 'Appeal', id: 'LIST' }],
    }),
    sendCallback: build.mutation<unknown, { tracker_id: string }>({
      query(body) {
        return {
          url: `/lk/order/${body.tracker_id}/callback`,
          method: 'POST',
        }
      },
      transformResponse: (response) => response,
      invalidatesTags: () => [{ type: 'Callback', id: 'LIST' }],
    }),
    createAppeal: build.mutation<
      unknown,
      {
        formData: FormData;
      }
    >({
      query(body) {
        return {
          url: apiEndPoints.CREATE_APPEAL,
          method: 'POST',
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // },
          body: body.formData,
          formData:true,
        }
      },
      transformResponse: (response) => response,
      invalidatesTags: () => [{ type: 'Appeal', id: 'LIST' }],
    }),
    closeAppeal: build.mutation<unknown, { external_tx_id: string }>({
      query(body) {
        return {
          url: '/report/close',
          method: 'POST',
          body,
        }
      },
      transformResponse: (response) => response,
      invalidatesTags: () => [{ type: 'Appeal', id: 'LIST' }],
    }),
    supportAppeal: build.mutation<unknown, { external_tx_id: string }>({
      query(body) {
        return {
          url: '/report/support',
          method: 'POST',
          body,
        }
      },
      transformResponse: (response) => response,
      invalidatesTags: () => [{ type: 'Appeal', id: 'LIST' }],
    }),
  }),
})

export const {
  useFetchAppealsQuery,
  useSendCallbackMutation,
  useCreateAppealMutation,
  useCloseAppealMutation,
  useSupportAppealMutation,
} = appealApi
