import React from 'react'
import {NavLink} from 'react-router-dom'
import {Divider, IconButton, ListItemIcon, Menu, MenuItem,} from '@mui/material'
import {Logout, Menu as MenuIcon} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'
import {getCookie} from 'typescript-cookie'
import {useAppSelector} from "../../hooks/redux";


const MobileMenu = ({ HeaderLink, pathname, exit }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const {t} = useTranslation()

	const {
		tx_operations_access,
		balance_access,
		statistics_access,
		transactions_access,
		invoice_access,
		withdrawal_access,
		conversion_access,
		orders_access
	} = useAppSelector((state) => state.base.accesses)

	const isSubUser = useAppSelector((state) => state.base.isSubUser)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	    setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
	    setAnchorEl(null)
	}

	return (
	    <div>
	        <IconButton
		        onClick={handleClick}
        		size="small"
		        sx={{ p: 0, m: 0 }}
        		aria-controls={open ? 'account-menu' : undefined}
		        aria-haspopup="true"
        		aria-expanded={open ? 'true' : undefined}
            >
                <MenuIcon sx={{ width: 40, height: 40, color: '#7154DC' }}/>
            </IconButton>
		    <Menu
    		    id="basic-menu"
		        anchorEl={anchorEl}
		        open={open}
		        onClose={handleClose}
		        MenuListProps={{
			        'aria-labelledby': 'basic-button',
		        }}
		    >
				{isSubUser ?
					<>
						{!!(tx_operations_access || balance_access || statistics_access || orders_access) &&
							<MenuItem onClick={handleClose}>
								<HeaderLink isActive={pathname === '/'}>
									<NavLink to={'/'}>
										{t('components.main')}
									</NavLink>
								</ HeaderLink>
							</MenuItem>
						}
						{!!(transactions_access || invoice_access || withdrawal_access) &&
							<MenuItem onClick={handleClose}>
								<HeaderLink isActive={pathname === '/balance'}>
									<NavLink to={'/balance'}>
										{t('components.balance')}
									</NavLink>
								</ HeaderLink>
							</MenuItem>
						}
						{(getCookie('is_fiat') === 'true' && !!conversion_access) && (
							<MenuItem onClick={handleClose}>
								<HeaderLink isActive={pathname === '/conversions'}>
									<NavLink to="/conversions">
										{t('components.conversions')}
									</NavLink>
								</HeaderLink>
							</MenuItem>
						)}
					</>
					:
					<>
						<MenuItem onClick={handleClose}>
							<HeaderLink isActive={pathname === '/'}>
								<NavLink to={'/'}>
									{t('components.main')}
								</NavLink>
							</ HeaderLink>
						</MenuItem>
						<MenuItem onClick={handleClose}>
							<HeaderLink isActive={pathname === '/balance'}>
								<NavLink to={'/balance'}>
									{t('components.balance')}
								</NavLink>
							</ HeaderLink>
						</MenuItem>
						{getCookie('is_fiat') === 'true' && (
							<MenuItem onClick={handleClose}>
								<HeaderLink isActive={pathname === '/conversions'}>
									<NavLink to="/conversions">
										{t('components.conversions')}
									</NavLink>
								</HeaderLink>
							</MenuItem>
						)}
						<MenuItem onClick={handleClose}>
							<HeaderLink isActive={pathname === '/settings'}>
								<NavLink to="/settings">
									{t('components.settings')}
								</NavLink>
							</HeaderLink>
						</MenuItem>
					</>
				}
			    <Divider />
			    <MenuItem sx={{ color: '#7154DC' }} onClick={exit}>
                    <ListItemIcon sx={{ color: '#7154DC' }}>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('components.logout')}
                </MenuItem>
		    </Menu>
	    </div>
	)
}

export default MobileMenu
