import React from 'react'
import styled from 'styled-components/macro'

import InputWithLabel from '../../components/input-with-label/input-with-label'

const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background-color: #fff;
	/* margin: 0px 0px 36px 0px; */
	border-radius: 25px 25px 0px 0px;
`

const InputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	gap: 32px;
	> div {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 32px;
	}
`

const ActionsWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	> div {
		:first-child {
			:hover > svg path {
				transition: all 0.3s ease 0s;
				stroke: #65B92F
			}
		}
		:last-child {
			:hover > svg path {
				transition: all 0.3s ease 0s;
				stroke: #D65A4A
			}
		}
	}
`

const Action = styled.div`
	cursor: pointer;
`

interface IFilterProps {
	setReceiver: (value: string) => void
	setId: (value: string) => void
	setAmount: (value: string) => void
	setTransationId: (value: string) => void
	setHash: (value: string) => void
	setDateFrom: (value: string | undefined) => void
	setDateTo: (value: string | undefined) => void
	id: string
	receiver: string
	amount: string
	transationId: string
	hash: string
	dateFrom: string | undefined
	dateTo: string | undefined
}


const FilterCrypto: React.FC<IFilterProps> = ({
	setReceiver,
	setId,
	setAmount,
	setTransationId,
	setHash,
	setDateFrom,
	setDateTo,
	id,
	receiver,
	amount,
	transationId,
	hash,
	dateFrom,
	dateTo,
}) => {

	return (
		<FilterWrapper>
			<InputsWrapper>
				<div>
					<InputWithLabel
						label="orderId"
						placeholder=""
						value={id}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value)}
					/>
                    <InputWithLabel
						label="transationId"
						placeholder=""
						value={transationId}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setTransationId(e.target.value)}
					/>
					<InputWithLabel
						label="requisites"
						placeholder=""
						value={receiver}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setReceiver(e.target.value)}
					/>
					<InputWithLabel
						label="amount"
						placeholder=""
						value={amount}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
					/>
					<InputWithLabel
						label="Hash"
						placeholder=""
						value={hash}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setHash(e.target.value)}
					/>
					<InputWithLabel
						label="dateFrom"
						placeholder=""
						value={dateFrom || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value.substring(0, 10))}
						type="date"
					/>
					<InputWithLabel
						label="dateTo"
						placeholder=""
						value={dateTo || ''}
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value.substring(0, 10))}
						type="date"
					/>
				</div>
			</InputsWrapper>
			{/* <ActionsWrapper>
				<Action onClick={() => { }}>
					<Check />
				</Action>
				<Action onClick={() => { }}>
					<Cancel />
				</Action>
			</ActionsWrapper> */}
		</FilterWrapper>
	)
}

export default React.memo(FilterCrypto)