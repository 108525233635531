import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { BASE_API_URL } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { ConversionListRequestData, ConversionsResponseData } from '../../types/types'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(BASE_API_URL)

export const conversionsApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'conversionsApi',
	tagTypes: ['Conversions'],
	endpoints:
		(build:
			EndpointBuilder<BaseQueryFn<string | FetchArgs, ConversionsResponseData[], FetchBaseQueryError, {}, FetchBaseQueryMeta>,
				'Conversions', 'conversionsApi'>,
		) => ({
			fetchConversions: build.query<ConversionsResponseData[], ConversionListRequestData>({
				query(body) {
					return {
						url: apiEndPoints.GET_CONVERSIONS,
						method: 'POST',
						body,
					}
				},
				transformResponse: (response) => response,
				providesTags: () => [{ type: 'Conversions', id: 'LIST' }],
			}),
			fetchConversionCsv: build.mutation<any, any>({
				queryFn: async (
					body: any,
					_queryApi: any,
					_extraOptions: any,
					baseQuery: any,
				) => {
					const result = await baseQuery({
						url: apiEndPoints.FETCH_CSV_CONVERSIONS,
						responseHandler: ((response: any) => response.blob()),
						method: 'POST',
						body,
					})
					const format = body?.format ? body.format === 'xls' ? 'xlsx' : body.format : 'csv'
					const hiddenElement = document.createElement('a')
					const url = window.URL || window.webkitURL
					const blobRes = url.createObjectURL(result.data)
					hiddenElement.href = blobRes
					hiddenElement.target = '_blank'
					hiddenElement.download = `Report_Conversion_${new Date().toString()}.${format}`
					hiddenElement.click()
					document.body.removeChild(hiddenElement)
					return { data: null }
				},
			}),
		}),
})

export const {
	useFetchConversionsQuery,
	useFetchConversionCsvMutation,
} = conversionsApi