import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import {useTranslation} from 'react-i18next'

import Button from '../../components/button/button'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import {useFetchBalanceCsvMutation} from '../../store/services/transactions-service'
import Popup from '../../components/popup/popup'
import CustomSelect, {IOption} from '../../components/custom-select/CustomSelect'
import {useAppSelector} from "../../hooks/redux";

const timezones = [
    {
        name: 'GMT-1',
        label: 'GMT+1',
    },
    {
        name: 'GMT-2',
        label: 'GMT+2',
    },
    {
        name: 'GMT-3',
        label: 'GMT+3',
    },
    {
        name: 'GMT-4',
        label: 'GMT+4',
    },
    {
        name: 'GMT-5',
        label: 'GMT+5',
    },
    {
        name: 'GMT-6',
        label: 'GMT+6',
    },
    {
        name: 'GMT-7',
        label: 'GMT+7',
    },
    {
        name: 'GMT-8',
        label: 'GMT+8',
    },
    {
        name: 'GMT-9',
        label: 'GMT+9',
    },
    {
        name: 'GMT-10',
        label: 'GMT+10',
    },
    {
        name: 'GMT-11',
        label: 'GMT+11',
    },
    {
        name: 'GMT-12',
        label: 'GMT+12',
    },
    {
        name: 'GMT-0',
        label: 'GMT+0',
    },
    {
        name: 'GMT+1',
        label: 'GMT-1',
    },
    {
        name: 'GMT+2',
        label: 'GMT-2',
    },
    {
        name: 'GMT+3',
        label: 'GMT-3',
    },
    {
        name: 'GMT+4',
        label: 'GMT-4',
    },
    {
        name: 'GMT+5',
        label: 'GMT-5',
    },
    {
        name: 'GMT+6',
        label: 'GMT-6',
    },
    {
        name: 'GMT+7',
        label: 'GMT-7',
    },
    {
        name: 'GMT+8',
        label: 'GMT-8',
    },
    {
        name: 'GMT+9',
        label: 'GMT-9',
    },
    {
        name: 'GMT+10',
        label: 'GMT-10',
    },
    {
        name: 'GMT+11',
        label: 'GMT-11',
    },
    {
        name: 'GMT+12',
        label: 'GMT-12',
    },
]

const Wrapper = styled.div`
    justify-self: end;

    @media (max-width: 655px) {
        justify-self: start;
    }
`

const CsvWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 100%;
`

const CSVBalanceButton = () => {
    const {t} = useTranslation()
    const [isCsvPopupVisible, setIsCsvPopupVisible] = React.useState(false)
    const [selectedTimezone, setSelectedTimezone] = React.useState<IOption>({name: 'GMT-0', label: 'GMT+0'})

    const [inputsScv, setInputsScv] = React.useState<any>({
        'dateFrom': new Date(),
        'dateTo': new Date(),
    })

    const [fetchCsv] = useFetchBalanceCsvMutation()

    function changeInputs(e: React.ChangeEvent<HTMLInputElement>, type?: string) {
        const { name, value } = e.target
        setInputsScv((prev: any) => ({
            ...prev,
            [name]: value,
        }))
    }

    return (
        <Wrapper>
            <Button 
                onClick={() => {
                    setIsCsvPopupVisible(true)
                }} 
                variant="primary"
            >
                <span>{t('components.downLoadCSV')}</span>
            </Button>
            <Popup isVisible={isCsvPopupVisible} setIsVisible={setIsCsvPopupVisible}>
                <CsvWrapper>
                    <InputWithLabel
                        required
                        name={'dateFrom'}
                        value={inputsScv['dateFrom']}
                        label="from"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        type="date"
                        placeholder=""
                    />
                    <InputWithLabel
                        required
                        name={'dateTo'}
                        value={inputsScv['dateTo']}
                        label="to"
                        type="date"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        placeholder=""
                    />
                    <div style={{ marginBottom: '10px'}}>
                        <CustomSelect
                            options={timezones}
                            width="240px"
                            height="34px"
                            label={'timezone'}
                            selectHandler={setSelectedTimezone}
                            selectedOption={selectedTimezone}
                        />
                    </div>
                    <Button
                        onClick={() => {
                            const dateFromForTimeZone = inputsScv['dateFrom'].replace('/', '-') + ' 00:00'
                            const dateToForTimeZone = inputsScv['dateTo'].replace('/', '-') + ' 00:00'
                            const dateFromWithTimeZone = moment.tz(dateFromForTimeZone, `Etc/${selectedTimezone.label}`).utc().format()
                            const dateToWithTimeZone = moment.tz(dateToForTimeZone, `Etc/${selectedTimezone.label}`).utc().format()
                            fetchCsv({
                                date_from: dateFromWithTimeZone,
                                date_to: dateToWithTimeZone,
                                token: 'USDTTRC',
                                timezone: selectedTimezone.name,
                             })
                        }}
                        variant="primary"
                    >
                        {t('components.toDownLoadCSV')}
                    </Button>
                </CsvWrapper>
            </Popup>
        </Wrapper>
    )
}

export default CSVBalanceButton
