import React from 'react'

import { useTokenCryptoFetchQuery } from '../store/services/tokens-service'

import { TToken } from '../types/types'

export const useTokens = () => {
    const [currencies, setCurrencies] = React.useState<TToken[]>([{ token: '', full_name: '', type: '' }])
    const { data: tokens } = useTokenCryptoFetchQuery(null)

    React.useEffect(() => {
        if (tokens && tokens?.tokens?.length > 0)
            setCurrencies(tokens.tokens)
    }, [tokens])

    return currencies
}