import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { BASE_API_URL_CRYPTO } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { IListRequestData, IListRequestDataCrypto, ITransactionsResponseDataCrypto } from '../../types/types'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(BASE_API_URL_CRYPTO)

export interface IResponseTransactionsCrypto {
    description: string
    status: string
    transaction_list: ITransactionsResponseDataCrypto[]
}

export const cryptoTransactionsApi = createApi({
    baseQuery: secureQuery,
    reducerPath: 'cryptoTransactionsApi',
    tagTypes: ['Transactions'],
    endpoints:
        (build:
            EndpointBuilder<BaseQueryFn<string | FetchArgs, IResponseTransactionsCrypto, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
                'Transactions', 'cryptoTransactionsApi'>,
        ) => ({
            fetchCryptoTransactions: build.mutation<IResponseTransactionsCrypto, IListRequestDataCrypto>({
                query({ limit, offset, type, token, amount, hash, tracker_id, receiver, client_transaction_id, status, date_from, date_to }) {
                    return {
                        url: `${apiEndPoints.GET_TRANSACTIONS_CRYPTO}?limit=${limit || '100000'}&offset=${offset || '0'}&type=${type}&sort_order=DESC&token=${token}&amount=${amount}&client_transaction_id=${client_transaction_id}&tracker_id=${tracker_id}&hash=${hash}&receiver=${receiver}&status=${status}&date_from=${date_from}&date_to=${date_to}
                        `,
                        method: 'GET',
                    }
                },
                transformResponse: (response: { description: string, status: string, transaction_list: ITransactionsResponseDataCrypto[] }) => response,
            }),
        }),
})

export const {
    useFetchCryptoTransactionsMutation,
} = cryptoTransactionsApi