import { useTranslation } from 'react-i18next'

import access from '../../assets/images/Accesses.svg'

import styled from 'styled-components/macro'
import { useAppDispatch } from '../../hooks/redux'
import React from 'react'
import Button from '../button/button'
import { setSubUserData } from '../../store/slices/base-slice'

const DateWrapper = styled.div`
  font-size: 17px;
`

const SubuserCard = ({ item, setEditSubuserOpen, setDeleteSubuserOpen }) => {
    const date = new Date(item?.sign_time).toLocaleString()
    const {t} = useTranslation()
    const dispatch = useAppDispatch();
  

  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', gap: '4px', margin: '16px auto', backgroundColor: 'white', minWidth: '250px', maxWidth: '400px'}}>
          <div>
            <strong>{t('components.id')}:</strong> {item.id}
          </div>
          <div>
            <strong>{t('components.login')}:</strong> { item.email}
          </div>
          <div style={{
            display:'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <strong>{t('components.access')}:</strong>  
            <Button onClick={() => {
              setEditSubuserOpen(true)
              dispatch(setSubUserData({
                  id: item.id,
                  enable: item?.enabled,
              }))
            }}>
              <img 
                src={access}
                style={{
                    width: '24px',
                    height: '24px',
                }}
                alt=""
              />
            </Button>
          </div>
          <div style={{
            display:'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <strong>{t('components.delete')}:</strong> 
            <Button onClick={() => {
                dispatch(setSubUserData({
                    id: item.id,
                }))
                setDeleteSubuserOpen(true)
            }} variant="secondary">
                {t('components.delete')}
            </Button>
          </div>
      </div>
    )
}

export default SubuserCard
