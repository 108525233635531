import {
    ActionReducerMapBuilder,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import {Cookies} from 'typescript-cookie'

import {AuthorizationStatus} from '../../libs/consts'
import {
    getConnectionStatusHandler,
    getKeypairHandler,
} from '../actions/base-actions'
import {IAccesses} from '../../types/types';
// import { authorizationHandler } from '../actions/base-actions'
// import { refreshTokenHandler } from '../actions/base-actions'

interface IBaseUserInfo {
    access_token: string;
    refresh_token: string;
}

export interface IBaseInitialState {
    page: string;
    authorizationStatus: string;
    userInfo: IBaseUserInfo;
    fingerkey: string;
    accesses: IAccesses;
    isSubUser: boolean;
    connectionStatus: boolean;
    links: string[];
    refreshToken?: string;
    subuserData: {
        id: number;
    }
}

const initialState: IBaseInitialState = {
    page: '/auth',
    authorizationStatus: '',
    isSubUser: false,
    accesses: {},
    subuserData: {
        id: 0,
    },
    userInfo: {} as IBaseUserInfo,
    fingerkey: Cookies.get('fingerKey')?.toString() || '',
    connectionStatus: false,
    links: [],
}

export const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<string>) => {
            state.page = action.payload
        },
        setAuthorizationStatus: (state, action: PayloadAction<string>) => {
            if (action.payload === AuthorizationStatus.NO_AUTH) {
                state.userInfo = {
                    access_token: '',
                    refresh_token: '',
                }
            }
            state.authorizationStatus = action.payload
        },
        setUserInfo: (state, action: PayloadAction<IBaseUserInfo>) => {
            state.userInfo = action.payload
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.userInfo = {
                ...state.userInfo,
                access_token: action.payload,
            }
        },
        setAccesses: (state, action) => {
            state.accesses = action.payload
            state.isSubUser = true
        },
        setSubUserData: (state, action) => {
            state.subuserData = action.payload
        },
        setIsSubUser: (state, action) => {
			state.isSubUser = action.payload
        },
        setFingerKey: (state, action: PayloadAction<string>) => {
            state.fingerkey = action.payload
        },
        setRefreshTokenAuth: (state, action: PayloadAction<string>) => {
            state.refreshToken = action.payload
        },
        setLinks: (state, action: PayloadAction<string>) => {
            if (action.payload.length > 0) {
                state.links = [...state.links, ...action.payload]
            }
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<IBaseInitialState>) => {
        // authorizationHandler(builder)
        // refreshTokenHandler(builder)
        getKeypairHandler(builder)
        getConnectionStatusHandler(builder)
    },
})

export const {
	setIsSubUser,
    setSubUserData,
    setAccesses,
    setPage,
    setAuthorizationStatus,
    setUserInfo,
    setToken,
    setFingerKey,
    setLinks,
    setRefreshTokenAuth,
} = baseSlice.actions

export default baseSlice.reducer
