import {
    createApi,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'


import { BASE_API_URL } from '../../libs/utils/api'

import { apiEndPoints } from '../../libs/consts'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(`${BASE_API_URL}`)

export const subusersApi = createApi({
    baseQuery: secureQuery,
    reducerPath: 'SubusersApi',
    tagTypes: ['Subusers'],
    
    endpoints: (
        build: EndpointBuilder<
            BaseQueryFn<
                string | FetchArgs,
                {},
                FetchBaseQueryError,
                {},
                FetchBaseQueryMeta
            >,
            'Subusers',
            'SubusersApi'
            >,
    ) => ({
        getSubUsers: build.query({
            query: ({offset, limit}) => ({
                url: apiEndPoints.GET_SUBUSERS,
                method: 'GET',
                params: {
                    offset,
                    limit,
                },
            }),
            providesTags: () => [{ type: 'Subusers', id: 'ID' }],
        }),
        createSubUser: build.mutation({
            query: ({email, password, otp, gen_otp, accesses }) => ({
                url: '/lk/subuser/add',
                method: 'POST',
                body: {
                    email,
                    password,
                    gen_otp,
                    Accesses: accesses,
                },
                headers: {
                    'X-OTP-Code': otp,
                },
            }),
            invalidatesTags: () => [{ type: 'Subusers', id: 'ID' }],
        }),
        deleteSubUser: build.mutation({
            query: ({ id , otp }) => ({
                url: apiEndPoints.DELETE_SUBUSERS,
                method: 'POST',
                body: {
                    subuser_id: id,
                },
                headers: {
                    'X-OTP-Code': otp,
                },
            }),
            invalidatesTags: () => [{ type: 'Subusers', id: 'ID' }],
        }),
        updateSubUser: build.mutation({
            query: ({ id, email, enabled, accesses, otp }) => ({
                url: apiEndPoints.UPDATE_SUBUSERS,
                method: 'POST',
                body: {
                    id,
                    email,
                    enabled,
                    Accesses: accesses,
                },
                headers: {
                    'X-OTP-Code': otp,
                },
            }),
            invalidatesTags: () => [{ type: 'Subusers', id: 'ID' }],
        }),
        getSubUserInfo: build.query({
            query: ({ id }) => ({
                url: apiEndPoints.GET_SUBUSERINFO,
                method: 'GET',
                params: {
                    subuser_id: id,
                },
            }),
        }),
        updateSubUserPassword: build.mutation(({
            query: ({id, newPassword, otp}) => ({
                url: apiEndPoints.UPDATE_SUBUSER_PASSWORD,
                method: 'POST',
                body: {
                    subuser_id: id,
                    new_password: newPassword,
                },
                headers: {
                    'X-OTP-Code': otp,
                },
            }),
        })),
        confirmSubuserOtp: build.mutation({
            query: ({subuser_id, otp_secret }) => ({
                url: '/lk/subuser/enable/otp',
                method: 'POST',
                body: {
                    subuser_id,
                    otp_secret,
                },
            }),
        }),
    }),
})

export const {
    useConfirmSubuserOtpMutation,
    useUpdateSubUserPasswordMutation,
    useDeleteSubUserMutation,
    useUpdateSubUserMutation,
    useGetSubUserInfoQuery,
    useCreateSubUserMutation,
    useGetSubUsersQuery,
} = subusersApi
