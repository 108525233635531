import { createApi, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/react'

import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'
import { apiEndPoints } from '../../libs/consts'

import { IBalance } from './../../types/balance'

import { secureQueryBuilder } from './_api'

export interface IBalanceData {
    type: string;
    market_currency: string;
    fiat_amount?: number;
    crypto_amount?: number;
    status_id?: number;
    external_order_id?: string;
    external_offer_id?: string;
    address?: string;
    tail?: string;
    order_by_done?: boolean;
    course?: number;
    limit?: number;
    offset?: number;
}

const secureQuery = secureQueryBuilder(BASE_API_URL)

export const balanceApi = createApi({
	baseQuery: secureQuery,
	reducerPath: 'balanceApi',
	tagTypes: ['Balance'],
	endpoints:
		(build:
			EndpointBuilder<BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
				'Balance', 'balanceApi'>,
		) => ({
			getBalance: build.query<IBalance[], string>({
				query(token) {
					return {
						url: `${apiEndPoints.BALANCE}?token=${token}`,
						method: 'GET',
					}
				},
				transformResponse: (response: { result: IBalance[] }) => response.result,
				providesTags: () => [{ type: 'Balance', id: 'ID' }],
			}),
		}),
})

export const {
	useGetBalanceQuery,
} = balanceApi
